import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_PRODUCTS_PENDING = 'SYNC_PRODUCTS_PENDING'
const SYNC_PRODUCTS_PROGRESS = 'SYNC_PRODUCTS_PROGRESS'
const SYNC_PRODUCTS_RESPONSE = 'SYNC_PRODUCTS_RESPONSE'
const SYNC_PRODUCT_PENDING = 'SYNC_PRODUCT_PENDING'
const SYNC_PRODUCT_RESPONSE = 'SYNC_PRODUCT_RESPONSE'
const SAVE_PRODUCTS_PENDING = 'SAVE_PRODUCTS_PENDING'
const SAVE_PRODUCTS_PROGRESS = 'SAVE_PRODUCTS_PROGRESS'
const SAVE_PRODUCTS_RESPONSE = 'SAVE_PRODUCTS_RESPONSE'
const RESAVE_PRODUCTS_PENDING = 'RESAVE_PRODUCTS_PENDING'
const RESAVE_PRODUCTS_PROGRESS = 'RESAVE_PRODUCTS_PROGRESS'
const RESAVE_PRODUCTS_RESPONSE = 'RESAVE_PRODUCTS_RESPONSE'
const SAVE_METADATA_PENDING = 'SAVE_METADATA_PENDING'
const SAVE_METADATA_PROGRESS = 'SAVE_METADATA_PROGRESS'
const SAVE_METADATA_RESPONSE = 'SAVE_METADATA_RESPONSE'
const SYNC_PRODUCT_METAFIELDS_PENDING = 'SYNC_PRODUCT_METAFIELDS_PENDING'
const SYNC_PRODUCT_METAFIELDS_PROGRESS = 'SYNC_PRODUCT_METAFIELDS_PROGRESS'
const SYNC_PRODUCT_METAFIELDS_RESPONSE = 'SYNC_PRODUCT_METAFIELDS_RESPONSE'
const SYNC_PRODUCT_VARIANT_METAFIELDS_PENDING = 'SYNC_PRODUCT_VARIANT_METAFIELDS_PENDING'
const SYNC_PRODUCT_VARIANT_METAFIELDS_PROGRESS = 'SYNC_PRODUCT_VARIANT_METAFIELDS_PROGRESS'
const SYNC_PRODUCT_VARIANT_METAFIELDS_RESPONSE = 'SYNC_PRODUCT_VARIANT_METAFIELDS_RESPONSE'
const SYNC_PRODUCT_IMAGES_PENDING = 'SYNC_PRODUCT_IMAGES_PENDING'
const SYNC_PRODUCT_IMAGES_PROGRESS = 'SYNC_PRODUCT_IMAGES_PROGRESS'
const SYNC_PRODUCT_IMAGES_RESPONSE = 'SYNC_PRODUCT_IMAGES_RESPONSE'
const CREATE_PRODUCT_CUSTOM_CONTENTS_PENDING = 'CREATE_PRODUCT_CUSTOM_CONTENTS_PENDING'
const CREATE_PRODUCT_CUSTOM_CONTENTS_PROGRESS = 'CREATE_PRODUCT_CUSTOM_CONTENTS_PROGRESS'
const CREATE_PRODUCT_CUSTOM_CONTENTS_RESPONSE = 'CREATE_PRODUCT_CUSTOM_CONTENTS_RESPONSE'
const DELETE_PRODUCT_CUSTOM_CONTENT_PENDING = 'DELETE_PRODUCT_CUSTOM_CONTENT_PENDING'
const DELETE_PRODUCT_CUSTOM_CONTENT_PROGRESS = 'DELETE_PRODUCT_CUSTOM_CONTENT_PROGRESS'
const DELETE_PRODUCT_CUSTOM_CONTENT_RESPONSE = 'DELETE_PRODUCT_CUSTOM_CONTENT_RESPONSE'
const EXPORT_PRODUCTS_PENDING = 'EXPORT_PRODUCTS_PENDING'
const EXPORT_PRODUCTS_PROGRESS = 'EXPORT_PRODUCTS_PROGRESS'
const EXPORT_PRODUCTS_RESPONSE = 'EXPORT_PRODUCTS_RESPONSE'
const IMPORT_PRODUCTS_PENDING = 'IMPORT_PRODUCTS_PENDING'
const IMPORT_PRODUCTS_PROGRESS = 'IMPORT_PRODUCTS_PROGRESS'
const IMPORT_PRODUCTS_RESPONSE = 'IMPORT_PRODUCTS_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_products_pending: true,
  sync_product_pending: false,
  sync_product_metafields_pending: false,
  sync_product_variant_metafields_pending: false,
  sync_product_images_pending: false,
  create_product_custom_contents_pending: false,
  delete_product_custom_content_pending: false,
  save_products_pending: false,
  save_metadata_pending: false,
  export_products_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  export_advanced_csv_download_url: null,
  import_products_pending: false,
  imported_entries: 0,
  progress: 0,
  error: false,
  products: [],
  tags: [],
  types: [],
  vendors: [],
}

function validateProduct(data, origin) {
  if (typeof data.general_errors !== 'undefined' && typeof data.general_errors.errors !== 'undefined') {
    data.general_errors.errors.forEach(item => {
      switch(item.object_key) {
        case 'handle' :
          data.target.handle = origin.target.handle;
          break;
      }
    })
  }
  return data;
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_PRODUCTS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_products_pending: true,
        export_products_pending: false,
        export_po_download_url: null,
        export_csv_download_url: null,
        export_advanced_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_PRODUCTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PRODUCTS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_products_pending: false,
          error: false,
          products: action.response.products,
          tags: action.response.tags,
          types: action.response.types,
          vendors: action.response.vendors
        }
      } else {
        return {
          ...state,
          sync_products_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    
    case SYNC_PRODUCT_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_product_pending: true,
        error: false,
      }
    }
    case SYNC_PRODUCT_RESPONSE: {
      if(action.response.success === true) {
        let newProducts = state.products.slice()
        let productToUpdate = newProducts.find(x => x.id == action.response.product.id)
        
        productToUpdate.synchronized = true;

        productToUpdate.progress = action.response.product.progress

        productToUpdate.source = {
          title: action.response.product.source.title,
          body_html: action.response.product.source.body_html,
          handle: action.response.product.source.handle,
          page_title: action.response.product.source.page_title,
          page_description: action.response.product.source.page_description
        }

        productToUpdate.target = {
          title: action.response.product.target.title,
          body_html: action.response.product.target.body_html,
          handle: action.response.product.target.handle,
          page_title: action.response.product.target.page_title,
          page_description: action.response.product.target.page_description
        }
        
        productToUpdate.options = action.response.product.options.slice()
        productToUpdate.optionNames = action.response.product.option_names.slice()
        productToUpdate.custom_contents = action.response.product.custom_contents.slice()
        productToUpdate.customSeo = action.response.product.custom_seo

        return {
          ...state,
          products: newProducts,
          sync_product_pending: false,
          error: false,
        }
      } else {
        return {
          ...state,
          sync_product_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          },
        }
      }
    }

    case SAVE_PRODUCTS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_products_pending: true,
        error: false,
      }
    }
    case SAVE_PRODUCTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_PRODUCTS_RESPONSE: {
      let newProducts = state.products.slice();
      let error = false;
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let newProduct = newProducts.find(x => x.id == response.product.id);
          let product = validateProduct(response.product,newProduct);
          newProduct.progress = product.progress;
          // commented out so source is not overwritten if backend returns insufficient data
          // newProduct.source = {...product.source};
          newProduct.target = {...product.target};
          newProduct.options = product.options.slice();
          newProduct.optionNames = product.option_names.slice();
          newProduct.metafields = product.metafields.slice();
          newProduct.images = product.images.slice();
          newProduct.custom_contents = product.custom_contents.slice();
          newProduct.errors = product.general_errors.errors;
        }
      })
      return {
        ...state,
        products: newProducts,
        save_products_pending: false,
        error: error
      }
    }

    case RESAVE_PRODUCTS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_products_pending: true,
        error: false,
      }
    }
    case RESAVE_PRODUCTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case RESAVE_PRODUCTS_RESPONSE: {
      return {
        ...state,
        save_products_pending: false,
        error: false
      }
    }

    case SAVE_METADATA_PENDING: {
      return {
        ...state,
        progress: 0,
        save_metadata_pending: true,
        error: false,
      }
    }
    case SAVE_METADATA_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_METADATA_RESPONSE: {
      let isOk = true;
      let newTags = state.tags.slice();
      let newTypes = state.types.slice();
      let newVendors = state.vendors.slice();
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false;
        } else {
          response.items.forEach(item => {
            if(item.type === 'tags') {
              newTags.find(x => x.id == item.id).target = item.target
            } else if(item.type === 'types') {
              newTypes.find(x => x.id == item.id).target = item.target
            } else if(item.type === 'vendors') {
              newVendors.find(x => x.id == item.id).target = item.target
            }
          })
        }
      })
      if(isOk === true) {
        return {
          ...state,
          tags: newTags,
          types: newTypes,
          vendors: newVendors,
          save_metadata_pending: false,
          error: false
        }
      } else {
        return {
          ...state,
          save_metadata_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
      return {
        ...state
      }
    }
    case SYNC_PRODUCT_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_product_metafields_pending: true
      }
    }
    case SYNC_PRODUCT_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PRODUCT_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newProducts = state.products.slice()
        let productToUpdate = newProducts.find(x => x.id == action.response.product_id)
        
        productToUpdate.metafields_synchronized = true;
        productToUpdate.metafields = action.response.metafields.slice()

        return {
          ...state,
          sync_product_metafields_pending: false,
          products: newProducts,
          error: false
        }
      } else {
        return {
          ...state,
          sync_product_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
      return {
        ...state,
        sync_product_metafields_pending: false
      }
    }
    case SYNC_PRODUCT_VARIANT_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_product_variant_metafields_pending: true
      }
    }
    case SYNC_PRODUCT_VARIANT_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PRODUCT_VARIANT_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newProducts = state.products.slice()
        let productToUpdate = newProducts.find(x => x.id == action.response.product_id)
        
        productToUpdate.variant_metafields_synchronized = true;
        productToUpdate.variant_metafields = action.response.variant_metafields.slice()

        return {
          ...state,
          sync_product_variant_metafields_pending: false,
          products: newProducts,
          error: false
        }
      } else {
        return {
          ...state,
          sync_product_variant_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
      return {
        ...state,
        sync_product_variant_metafields_pending: false
      }
    }
    case SYNC_PRODUCT_IMAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_product_images_pending: true
      }
    }
    case SYNC_PRODUCT_IMAGES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PRODUCT_IMAGES_RESPONSE: {
      if(action.response.success === true) {
        let newProducts = state.products.slice()
        let productToUpdate = newProducts.find(x => x.id == action.response.product_id)
        
        productToUpdate.images_synchronized = true;
        productToUpdate.images = action.response.images.slice()

        return {
          ...state,
          sync_product_images_pending: false,
          products: newProducts,
          error: false
        }
      } else {
        return {
          ...state,
          sync_product_images_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
      return {
        ...state,
        sync_product_images_pending: false
      }
    }
    case CREATE_PRODUCT_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_product_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_PRODUCT_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_PRODUCT_CUSTOM_CONTENTS_RESPONSE: {
      let newProducts = state.products.slice()
      let error = false;

      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let productToUpdate = newProducts.find(x => x.id == response.product_id)
          productToUpdate.custom_contents.push({
            id: response.custom_content.id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          });
        }
      }

      return {
        ...state,
        products: newProducts,
        create_product_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_PRODUCT_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_product_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_PRODUCT_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_PRODUCT_CUSTOM_CONTENT_RESPONSE: {
      let newProducts = state.products.slice()
      let error = false;

      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        let productToUpdate = newProducts.find(x => x.id == action.response.product_id)
        productToUpdate.custom_contents = productToUpdate.custom_contents.filter(x => x.id != action.response.id)
      }

      return {
        ...state,
        products: newProducts,
        delete_product_custom_content_pending: false,
        error: error
      }
    }
    case EXPORT_PRODUCTS_PENDING: {
      return {
        ...state,
        progress: 0,
        export_products_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        export_advanced_csv_download_url: null
      }
    }
    case EXPORT_PRODUCTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_PRODUCTS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_products_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),
          export_advanced_csv_download_url: _exportDownloadLinkDecorator(action.response.advanced_csv_download_url, action.options)
        }
      } else {
        return {
          ...state,
          progress: 100,
          export_products_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case IMPORT_PRODUCTS_PENDING: {
      return {
        ...state,
        progress: 0,
        import_products_pending: true,
        imported_entries: 0
      }
    }
    case IMPORT_PRODUCTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_PRODUCTS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          import_products_pending: false,
          imported_entries: action.response.num_imported_entries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_products_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_products_pending: false,
        export_advanced_csv_download_url: null,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncProducts = (language, sync_reset, callback) => {
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCTS_PENDING
    })

    API.syncProducts(
      language,
      progress => {
        dispatch({
          type: SYNC_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PRODUCTS_RESPONSE,
          response: response,
          action: () => syncProducts(language, sync_reset, callback)
        });
        if(callback) {
          callback(response);
        }
      },
      sync_reset
    )
  }
}

export const resyncProducts = (language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCTS_PENDING
    })

    API.resyncProducts(
      language,
      progress => {
        dispatch({
          type: SYNC_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PRODUCTS_RESPONSE,
          response: response,
          action: () => resyncProducts(language)
        }
    )
    })
  }
}

export const syncProduct = (productId, languageId) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCT_PENDING
    })

    API.syncProduct(
      productId,
      languageId,
      response => {
        dispatch({
          type: SYNC_PRODUCT_RESPONSE,
          response: response,
          action: () => syncProduct(productId, languageId)
        })
      }
    )
  }
}

export const saveProducts = (language, items, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_PRODUCTS_PENDING
    })

    API.saveProducts(
      language,
      items,
      force,
      progress => {
        dispatch({
          type: SAVE_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_PRODUCTS_RESPONSE,
          response: response,
          items: items,
          action: () => saveProducts(language, items, force)
        })
      })
  }
}

export const resaveProducts = (language, items) => {  
  return dispatch => {
    dispatch({
      type: RESAVE_PRODUCTS_PENDING
    })

    API.resaveProducts(
      language,
      items,
      progress => {
        dispatch({
          type: RESAVE_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: RESAVE_PRODUCTS_RESPONSE,
          response: response,
          items: items,
          action: () => resaveProducts(language, items)
        })
      })
  }
}

export const saveMetadata = (language, tags, types, vendors, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_METADATA_PENDING
    })

    API.saveProductsMetadata(
      language,
      tags,
      types,
      vendors,
      force,
      progress => {
        dispatch({
          type: SAVE_METADATA_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_METADATA_RESPONSE,
          response: response,
          action: () => saveMetadata(language, tags, types, vendors, force)
        })
      })
  }
}

export const syncProductMetafields = (product, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCT_METAFIELDS_PENDING
    })

    API.syncProductMetafields(
      product,
      language,
      progress => {
        dispatch({
          type: SYNC_PRODUCT_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PRODUCT_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncProductMetafields(product, language)
        }
      )
    })
  }
}

export const syncProductVariantMetafields = (product, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCT_VARIANT_METAFIELDS_PENDING
    })

    API.syncProductVariantMetafields(
      product,
      language,
      progress => {
        dispatch({
          type: SYNC_PRODUCT_VARIANT_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PRODUCT_VARIANT_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncProductVariantMetafields(product, language)
        }
      )
    })
  }
}

export const syncProductImages = (product, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PRODUCT_IMAGES_PENDING
    })

    API.syncProductImages(
      product,
      language,
      progress => {
        dispatch({
          type: SYNC_PRODUCT_IMAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PRODUCT_IMAGES_RESPONSE,
          response: response,
          action: () => syncProductImages(product, language)
        }
      )
    })
  }
}

export const createProductCustomContents = (product, language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_PRODUCT_CUSTOM_CONTENTS_PENDING
    })

    API.createProductCustomContents(
      product,
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_PRODUCT_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_PRODUCT_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createProductCustomContents(product, language, items)
        })
      })
  }
}

export const deleteProductCustomContent = (product, ids) => {
  return dispatch => {
    dispatch({
      type: DELETE_PRODUCT_CUSTOM_CONTENT_PENDING
    })

    API.deleteProductCustomContent(
      product,
      ids,
      progress => {
        dispatch({
          type: DELETE_PRODUCT_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_PRODUCT_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deleteProductCustomContent(product, ids)
        })
      })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}

/*
export const exportProducts = (language) => {
  return dispatch => {
    dispatch({
      type: EXPORT_PRODUCTS_PENDING
    })

    API.exportProducts(
      language,
      progress => {
        dispatch({
          type: EXPORT_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_PRODUCTS_RESPONSE,
          response: response,
          action: () => exportProducts(language)
        }
      )
    })
  }
}
*/

export const exportProducts = (language, options) => {
  console.log(options)
  return dispatch => {
    dispatch({
      type: EXPORT_PRODUCTS_PENDING
    })

    API.exportProducts(
      language,
      options,
      progress => {
        dispatch({
          type: EXPORT_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_PRODUCTS_RESPONSE,
          response: response,
          options: options,
          action: () => exportProducts(language, options)
        }
      )
    })
  }
}

export const importProducts = (file, fileType, options, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_PRODUCTS_PENDING
    })

    API.importProducts(
      file,
      fileType,
      options,
      language,
      progress => {
        dispatch({
          type: IMPORT_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_PRODUCTS_RESPONSE,
          response: response,
          options: options,
          action: () => importProducts(file, fileType, options, language)
        }
      )
    })
  }
}

export const exportProductMetafields = (language, callback) => {
  return dispatch => {
    dispatch({
      type: EXPORT_PRODUCTS_PENDING
    })

    API.exportProductMetafields(
      language,
      progress => {
        dispatch({
          type: EXPORT_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_PRODUCTS_RESPONSE,
          response: response,
          action: () => exportProductMetafields(language)
        }
      )
      callback();
    })
  }
}

export const importProductMetafields = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_PRODUCTS_PENDING
    })

    API.importProductMetafields(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_PRODUCTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_PRODUCTS_RESPONSE,
          response: response,
          action: () => importProductMetafields(file, fileType, language)
        }
      )
    })
  }
}
import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_STATIC_THEME_PENDING = 'SYNC_STATIC_THEME_PENDING'
const SYNC_STATIC_THEME_PROGRESS = 'SYNC_STATIC_THEME_PROGRESS'
const SYNC_STATIC_THEME_RESPONSE = 'SYNC_STATIC_THEME_RESPONSE'

const SYNC_STATIC_THEME_ASSET_PENDING = 'SYNC_STATIC_THEME_ASSET_PENDING'
const SYNC_STATIC_THEME_ASSET_PROGRESS = 'SYNC_STATIC_THEME_ASSET_PROGRESS'
const SYNC_STATIC_THEME_ASSET_RESPONSE = 'SYNC_STATIC_THEME_ASSET_RESPONSE'

const SAVE_STATIC_THEME_PENDING = 'SAVE_STATIC_THEME_PENDING'
const SAVE_STATIC_THEME_PROGRESS = 'SAVE_STATIC_THEME_PROGRESS'
const SAVE_STATIC_THEME_RESPONSE = 'SAVE_STATIC_THEME_RESPONSE'

const EXPORT_STATIC_THEME_PENDING = 'EXPORT_STATIC_THEME_PENDING'
const EXPORT_STATIC_THEME_PROGRESS = 'EXPORT_STATIC_THEME_PROGRESS'
const EXPORT_STATIC_THEME_RESPONSE = 'EXPORT_STATIC_THEME_RESPONSE'

const IMPORT_STATIC_THEME_PENDING = 'IMPORT_STATIC_THEME_PENDING'
const IMPORT_STATIC_THEME_PROGRESS = 'IMPORT_STATIC_THEME_PROGRESS'
const IMPORT_STATIC_THEME_RESPONSE = 'IMPORT_STATIC_THEME_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_static_theme_pending: true,
  save_static_theme_pending: false,
  sync_static_theme_asset_pending: false,
  export_static_theme_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_static_theme_pending: false,
  imported_entries: 0,
  assets: [],
  progress: 0,
  error: false,
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_STATIC_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_static_theme_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_STATIC_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_STATIC_THEME_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_static_theme_pending: false,
          assets: action.response.assets.slice()
        }
      } else {
        return {
          ...state,
          static_static_theme_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }

    case SYNC_STATIC_THEME_ASSET_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_static_theme_asset_pending: true,
        error: false,
      }
    }
    case SYNC_STATIC_THEME_ASSET_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_STATIC_THEME_ASSET_RESPONSE: {
      if(action.response.success === true) {
        let newAssets = state.assets.slice()
        let assetToUpdate = newAssets.find(x => x.id == action.response.asset_id)
        
        assetToUpdate.synchronized = true
        assetToUpdate.progress = action.response.progress
        assetToUpdate.out_of_sync = action.response.out_of_sync
        assetToUpdate.strings = action.response.strings.slice()
        
        return {
          ...state,
          assets: newAssets,
          sync_static_theme_asset_pending: false
        }
      } else {
        return {
          ...state,
          sync_static_theme_asset_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          },
        }
      }
    }

    case SAVE_STATIC_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        save_static_theme_pending: true,
        error: false,
      }
    }
    case SAVE_STATIC_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_STATIC_THEME_RESPONSE: {
      let isOk = true
      let newAssets = state.assets.slice()
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          if(response.type == 'prepared') {
            const assetToUpdate = newAssets.find(x => x.id == response.asset_id)
            if(assetToUpdate) {
              assetToUpdate.progress = response.progress
            }
          } else {
            Object.keys(response.assets).forEach(key => {
              const assetToUpdate = newAssets.find(x => x.id == key)
              if(assetToUpdate) {
                response.assets[key].forEach(string => {
                    const stringToUpdate = assetToUpdate.strings.find(x => x.id == string.id)
                    if(stringToUpdate) {
                      stringToUpdate.source = string.source
                      stringToUpdate.target = string.target
                    }
                })
              }
            })          
          }
        }
      })

      return {
        ...state,
        assets: newAssets,
        save_static_theme_pending: false
      }
    }
    case EXPORT_STATIC_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        export_static_theme_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_STATIC_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_STATIC_THEME_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_static_theme_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),

        }
      } else {
        return {
          ...state,
          progress: 100,
          export_static_theme_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }

    case IMPORT_STATIC_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        import_static_theme_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_STATIC_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_STATIC_THEME_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_static_theme_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_static_theme_pending: false,
          imported_entries: importedEntries,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_static_theme_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const syncStaticTheme = (themeId, languageId, progress) => { 
  return dispatch => {
    dispatch({
      type: SYNC_STATIC_THEME_PENDING
    })

    API.syncStaticTheme(
      themeId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_STATIC_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_STATIC_THEME_RESPONSE,
          response: response,
          action: () => syncStaticTheme(themeId, languageId, progress),
        })
      }
    )
  }
}

export const syncStaticThemeAsset = (themeId, assetId, languageId, progress) => { 
  return dispatch => {
    dispatch({
      type: SYNC_STATIC_THEME_ASSET_PENDING
    })

    API.syncStaticThemeAsset(
      themeId,
      assetId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_STATIC_THEME_ASSET_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_STATIC_THEME_ASSET_RESPONSE,
          response: response,
          action: () => syncStaticThemeAsset(themeId, assetId, languageId, progress)
        })
      }
    )
  }
}

export const saveStaticTheme = (themeId, languageId, items, progress) => { 
  return dispatch => {
    dispatch({
      type: SAVE_STATIC_THEME_PENDING
    })

    API.saveStaticTheme(
      themeId,
      languageId,
      items,
      progress => {
        dispatch({
          type: SAVE_STATIC_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_STATIC_THEME_RESPONSE,
          response: response,
          items: items,
          action: () => saveStaticTheme(themeId, languageId, items, progress)
        })
      }
    )
  }
}

export const exportStaticTheme = (theme, language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_STATIC_THEME_PENDING
    })

    API.exportStaticTheme(
      theme,
      language,
      progress => {
        dispatch({
          type: EXPORT_STATIC_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_STATIC_THEME_RESPONSE,
          response: response,
          options: options,
          action: () => exportStaticTheme(theme, language, options)
        }
      )
    })
  }
}

export const importStaticTheme = (file, fileType, theme, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_STATIC_THEME_PENDING
    })

    API.importStaticTheme(
      file,
      fileType,
      theme,
      language,
      progress => {
        dispatch({
          type: IMPORT_STATIC_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_STATIC_THEME_RESPONSE,
          response: response,
          action: () => importStaticTheme(file, fileType, theme, language)
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import AddOutlined from '@material-ui/icons/AddOutlined';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import TranslationFields from './TranslationFields';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

class CustomFields extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      showInfo: true,
      collapsed: true,
      updateNumber: 0,
      anchorEl: null,
    };
  }


  componentWillReceiveProps = (newProps) => {

  }

  handleClick = (evt) => {
    this.setState({
      showInfo: false
    })
  }

  handleToggleCollapse = (evt) => {
    if(this.state.collapsed === true) {
      if(this.props.onExpand) {
        this.props.onExpand(this.props.id)
      }
    }

    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  handleChange = (event, data) => {
    this.props.onChange(null, {
      ...data,
      parentField: this.props.id
    });
  }

  handleOptionsMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOptionsMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  onClickAddItem = () => {
    this.props.onAddItem(this.props.itemId);
    this.handleOptionsMenuClose();
  }

  render() {
    const {
      t,
      classes,
      fields,
      isFirst,
      allowAutoTranslation, 
      allowFieldFiltering, 
    } = this.props;

    const {
      showInfo,
      collapsed,
      anchorEl
    } = this.state;

    return (
      <div className={classNames('collapsable-fields', isFirst && 'first', this.props.type, this.props.id, classes.root)}>
        <Divider className={classes.divider} />

        <Typography className={classes.label} variant="h5" component="p" onClick={this.handleToggleCollapse}>
          {collapsed ? <AddOutlined fontSize={'small'} className={classes.toggleIcon} /> : <RemoveOutlined fontSize={'small'} className={classes.toggleIcon} />}
          {this.props.label}
        </Typography> 
          {!collapsed && 
            <span style={{position: 'absolute', right: '0'}}>
              <IconButton style={{padding: '6px', position: 'relative', bottom: '40px', right: '14px'}}
                aria-owns={anchorEl ? 'options-menu' : undefined}
                aria-haspopup="true"
                aria-label="Options list"
                onClick={this.handleOptionsMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="options-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleOptionsMenuClose}
                disableAutoFocusItem
                style={{ top: 56, right: 0 }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={this.onClickAddItem}>
                  <AddIcon style={{marginRight:'10px'}}/>
                  {t('customContents.addNewCustomContent')}...
                </MenuItem>
              </Menu>                
            </span>
          }
        <Collapse
          in={!collapsed}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div className={classes.collapseContainer}>
            <Divider className={classes.divider} />
            <TranslationFields
              data={fields} 
              nested
              id={this.props.id}
              parentField={'customfields'}
              isPending={this.state.isPending} 
              onChange={this.handleChange} 
              from={this.props.from}
              to={this.props.to}
              forceUpdate={this.props.forceUpdate}
              triggerClearAllTranslations={this.props.triggerClearAllTranslations}
              triggerDiscardChanges={this.props.triggerDiscardChanges}
              emptyMessage={this.props.emptyMessage}
              allowAutoTranslation={allowAutoTranslation}
              allowFieldFiltering={(this.props.filterQuery !== '')}

              onDeleteItem={this.props.onDeleteItem}
              filterQuery={(this.props.filterQuery !== '') ? this.props.filterQuery : ''}
              type={this.props.type}
            />            
          </div>
        </Collapse>

      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginLeft: '-24px',
    marginRight: '-24px',
    backgroundColor: '#fafafa'
  },
  collapseContainer: {
    
  },
  divider: {
    //margin: '0 -24px',
  },
  label: {
    color: '#555',
    cursor: 'pointer',
    padding: '12px 24px',
    fontSize: '1rem',
  },
  toggleIcon: {
    margin: '-2px 12px 0 0'
  },
  cta: {
    margin: '12px 0',
    padding: '24px 16px',
    textAlign: 'center',
    cursor: 'pointer',
    border: 'dashed 1px rgb(92, 107, 192)',
    backgroundColor: 'rgb(235, 237, 247)',
    '& p': {
      color: 'rgb(92, 107, 192)',
    },
    '& span': {
      color: 'rgb(92, 107, 192)',
    },
  },
});

export default withStyles(styles)(withTranslation()(CustomFields));
import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';

import TranslationFields from "./TranslationFields";

const flattenObject = function(ob) {
	var toReturn = {};
	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		if ((typeof ob[i]) == 'object') {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};
function compareObjects(a, b) {
	let fa = flattenObject(a);
	let fb = flattenObject(b);
	if(fa.length != fb.length) return false;
	for (var i in fa) {
		let key = i;
		if(!fb.hasOwnProperty(key)) return false;
	}
  return true;
}
function set(obj, path, value) {
	var schema = obj;  // a moving reference to internal objects within obj
	var pList = path.split('.');
	var len = pList.length;
	for(var i = 0; i < len-1; i++) {
			var elem = pList[i];
			if( !schema[elem] ) schema[elem] = {}
			schema = schema[elem];
	}
	schema[pList[len-1]] = value;
}



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const JsonEditor = (props) => {
	let parsedJsonString = null;
	let flatObjectSource = null;
	let fields = [];

  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [sourceObject, setSourceObject] = useState({});
  const [flatSourceObject, setFlatSourceObject] = useState(null);
  const [targetObject, setTargetObject] = useState({});
  const [flatTargetObject, setFlatTargetObject] = useState(null);
  const [error, setError] = useState(null);

	// onComponentDidUpdate
  useEffect(() => {
		if(open) {
			try {
				//console.log({targetObject, flatTargetObject, error});
				const parsedTargetJsonString = JSON.parse(props.target);
				setTargetObject(parsedTargetJsonString);
				setFlatTargetObject(flattenObject(parsedTargetJsonString));
				setError(null);
			}
			catch (e) {}			
		}
  }, [props.target]);


	// Initialisation
	try {
		parsedJsonString = JSON.parse(props.source);
		setFlatSourceObject(flattenObject(parsedJsonString));
		try {
			const parsedTargetJsonString = JSON.parse(props.target);
			setFlatTargetObject(flattenObject(parsedTargetJsonString));

			if(!compareObjects(parsedJsonString, parsedTargetJsonString)) {
				setError('structure')
			}
		}
		catch (e) {}
	}
	catch (e) {}

	if(flatSourceObject) {
		Object.keys(flatSourceObject).map((key) => {
			fields.push({
				id: key,
				type: 'text',
				label: key,
				source: flatSourceObject[key],
				target: (flatTargetObject && flatTargetObject[key]) ? flatTargetObject[key] : ''
			});
		});		
	}

	const rightMargin = (props.parentField === 'customfields' || window.location.pathname.indexOf('/custom') >= 0);


  const handleOpen = () => {
		let contentStr = props.target;
		if(contentStr === '') {
			contentStr = props.source;
		}
		try {
			setTargetObject(JSON.parse(contentStr));
			setFlatTargetObject(flattenObject(JSON.parse(contentStr)));

			if(!compareObjects(JSON.parse(props.source), JSON.parse(contentStr))) {
				setError('structure');
			} else {
				setError(null);
			}
		}
		catch (e) {}
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditingDone = () => {
		props.onChange(null, {
			id: props.id,
			changed: true,
			initial: props.target,
			target: JSON.stringify(targetObject),
			parentField: props.parentField,
		})

    setOpen(false);
  };

	const handleChange = (e, data) => {
		set(targetObject, data.id, data.target)
	}

	const importFromOriginal = () => {
		props.onChange(null, {
			id: props.id,
			changed: true,
			initial: props.target,
			target: props.source,
			parentField: props.parentField,
		})
	};

	return (
		<div>
			{parsedJsonString &&
				<Tooltip title="Translate JSON Object" arrow>
					<IconButton color="default" component="span" size="small" variant="contained" className={classes.openBtn} style={{marginRight: rightMargin ? 40 : 0}} onClick={handleOpen}>
						<MenuOpenRoundedIcon />
					</IconButton>
				</Tooltip>
			}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
				fullWidth={true}
				maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">JSON Object Translation</DialogTitle>
				<div className={classes.msgBox}>
					{error === 'structure' ?
						<Alert severity="error">
							The object structure of your target does not match with the original structure.
						</Alert>
					:
						<Alert severity="info">Note: Do not translate fields that could be used programmatically, such as <strong>"null"</strong>, <strong>"true"</strong>, and <strong>"false"</strong>.</Alert>
					}
				</div>
        <DialogContent>
				{flatSourceObject ?
					<div>
						{error === null ?
							<TranslationFields 
								data={fields} 
								nested
								onChange={handleChange} 
								parentField="json-editor"
							/>
						:
							<p style={{textAlign: 'center', padding: 16}}>
								It is necessary that both the original and the target object have an identical structure.<br/>
								This can be fixed by importing the original content.<br/>
								<Button variant="outlined" color="secondary" onClick={importFromOriginal} style={{marginTop: 8}}>Import from original</Button>
							</p>
						}
					</div>
					:
					<p>No items found in object.</p>
				}
        </DialogContent>

				{error === null &&
					<DialogActions>
						<Button variant="outlined" onClick={handleClose} color="default">
							Cancel
						</Button>
						<Button variant="contained" onClick={handleEditingDone} color="secondary" endIcon={<CheckIcon />}>
							Editing Done
						</Button>
					</DialogActions>
				}
      </Dialog>
		</div>
	);
}


const styles = (theme) => ({
  msgBox: {
    width: '100%',
    '& > *': {
      borderRadius: 0,
    },
  },
	openBtn: {
		position: 'absolute',
		top: 16,
		right: 16,
	}
});

export default withStyles(styles)(JsonEditor);
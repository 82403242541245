import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Collapse from '@material-ui/core/Collapse'
import TranslationFields from './TranslationFields'
import { Trans, withTranslation, useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { bindActionCreators } from 'redux'
import { updateAutoTranslations } from '../modules/shop'
import { connect } from 'react-redux'
import withRouter from 'with-router'
import SourceToTargetMenu from './SourceToTargetMenu'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#1a237e',
    color: '#fff',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    padding: '12px 14px',
    boxShadow:
      '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)'
  }
}))(Tooltip)

class SeoFields extends React.Component {
  cachedFields = []

  constructor(props) {
    super(props)

    this.state = {
      showInfo: true,
      collapsed: true,
      updateNumber: 0,
      inheritSeoFields: true,
      page_title: '',
      page_description: ''
    }
  }

  componentDidMount = () => {
    // Calc if SEO fields are are inheriting
    let inheritSeoFields = true
    if (
      (this.props.fields[0] && this.props.fields[0].customized) ||
      (this.props.fields[1] && this.props.fields[1].customized)
    ) {
      inheritSeoFields = false
    }

    this.setState({
      inheritSeoFields: inheritSeoFields,
      // TODO - remove page_title and page_description state since they are not used anymore?
      page_title: this.getSeoFieldValue(
        'page_title',
        this.props.fields[0] && this.props.fields[0].customized
      ),
      page_description: this.getSeoFieldValue(
        'page_description',
        this.props.fields[1] && this.props.fields[1].customized
      )
    })

    if (this.props.onLoaded) {
      setTimeout(() => {
        if (this.hasMounted) {
          this.props.onLoaded()
        }
      }, 100)
    }
  }

  handleChange = (event, data) => {
    let targetField = this.props.fields.find(x => x.id == event.target.id)

    let passThroughData = !event
      ? data
      : {
          id: event.target.id,
          changed: this.props.target != event.target.value,
          initial: targetField.target,
          target: event.target.value
        }

    this.props.onChange(null, {
      ...passThroughData,
      parentField: this.props.id
    })
  }

  /**
   * Returns the value of a given SEO field depending on state.inheritSeoFields
   * and if the original fields target has a value or not. If the target has no value it would
   * return field.source instead of field.target
   *
   * @param {*} fieldId
   */
  getSeoFieldValue = (fieldId, customized) => {
    let field = null
    let val = null
    let maxLength = fieldId === 'page_title' ? 70 : 320

    if (!customized) {
      let _fieldId = fieldId
        .replace('page_', '')
        .replace('description', 'body_html')
      field = this.props.rawData.find(x => x.id === _fieldId)

      if (field && field.target == '' && this.props.fields.length) {
        if (
          field.source !== this.props.fields.find(x => x.id === fieldId).source
        ) {
          field = this.props.fields.find(x => x.id === fieldId)
        }
      } else if (
        field &&
        this.props.fields.length &&
        this.props.fields.find(x => x.id === fieldId).target !== ''
      ) {
        field = this.props.fields.find(x => x.id === fieldId)
      }
    } else {
      field = this.props.fields.find(x => x.id === fieldId)
    }

    if (field) {
      let val =
        field.target !== '' || customized
          ? this.convertHtmltoText(field.target)
          : this.convertHtmltoText(field.source)
      return this.trimSeoField(val, maxLength)
    }
    return ''
  }

  convertHtmltoText(html) {
    if (!html) return false
    var res = html.replace(/<[^>]*>/g, '')
    return res
  }

  trimSeoField(string, maxLength = 70) {
    if (!string) return null
    var trimmedString =
      string.length > maxLength
        ? string.substring(0, maxLength - 3) + '...'
        : string
    return trimmedString
  }

  getProps(field) {
    const toLanguage = this.props.languages.languages.find(
      x => x.id == this.props.shop.shop.edit_language
    )

    let myInputProps = {
      //maxLength: field.maxLength ? field.maxLength : null,
      dir: toLanguage && toLanguage.rtl ? 'rtl' : 'ltr'
    }

    if (field.customized === false) {
      myInputProps['disabled'] = 'disabled'
    }

    return myInputProps
  }

  renderTranslationField(field) {
    const { t, classes, fields, isFirst } = this.props
    return (
      <TextField
        id={field.id}
        data={field}
        multiline={field.multiline}
        rows={field.rows}
        rowsMax={field.rows ? field.rows : 1}
        fullWidth
        value={this.getSeoFieldValue(field.id, field.customized)}
        onChange={this.handleChange}
        margin="none"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            root: classes.textField,
            multiline: classes.textField,
            input: classes.inputField,
            inputMultiline: classes.inputField
          }
        }}
        inputProps={this.getProps(field)}
        className={classes.textField}
        focused={
          field.customized ? field.id == this.props.getFieldIdByHash() : false
        }
      />
    )
  }

  renderTranslationFieldWithTooltip(field) {
    const { t, classes, fields, isFirst } = this.props

    return (
      <HtmlTooltip
        arrow
        title={
          field.customized ? (
            ''
          ) : (
            <React.Fragment>
              <InfoIcon
                style={{
                  float: 'right',
                  position: 'relative',
                  left: 8,
                  bottom: 6
                }}
              />
              <div>
                <Typography style={{ paddingBottom: 8 }}>
                  <span color="inherit">{t('itemsList.seoTooltipTitle')}</span>
                </Typography>
                <span style={{ fontSize: 12 }}>
                  {t('itemsList.seoTooltipDescription')}
                </span>
              </div>
            </React.Fragment>
          )
        }>
        {this.renderTranslationField(field)}
      </HtmlTooltip>
    )
  }

  render() {
    const { t, classes, fields, isFirst } = this.props
    const {} = this.state

    const truncate = (input, len) =>
      input.length > len ? `${input.substring(0, len)}...` : input
    const fromLanguage = this.props.languages.languages.find(
      x => x.base == true
    )

    return (
      <div
        className={classNames(
          'collapsable-fields',
          isFirst && 'first',
          this.props.type,
          this.props.id,
          classes.root
        )}>
        <Typography
          variant="subtitle1"
          className={classes.headline}
          style={
            {
              /*marginBottom: this.state.inheritSeoFields ? 0 : -16*/
            }
          }>
          {this.state.inheritSeoFields
            ? t('itemsList.seoPreview')
            : t('itemsList.seoFields')}
        </Typography>

        <Collapse in={this.state.inheritSeoFields}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <InfoIcon
                  style={{
                    float: 'right',
                    position: 'relative',
                    left: 8,
                    bottom: 6
                  }}
                />
                <div>
                  <Typography style={{ paddingBottom: 8 }}>
                    <span color="inherit">
                      {t('itemsList.seoTooltipTitle')}
                    </span>
                  </Typography>
                  <span style={{ fontSize: 12 }}>
                    {t('itemsList.seoTooltipDescription')}
                  </span>
                </div>
              </React.Fragment>
            }>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h4" style={{ marginBottom: 4 }}>
                {this.getSeoFieldValue('page_title', false)}
              </Typography>
              <Typography variant="caption" component="p">
                {this.getSeoFieldValue('page_description', false)}
              </Typography>
            </Paper>
          </HtmlTooltip>
        </Collapse>
        <Collapse in={!this.state.inheritSeoFields}>
          <div style={{ marginTop: -12 }}>
            {fields.map(field => (
              <React.Fragment>
                <Grid
                  container
                  spacing={3}
                  style={{ paddingRight: 24, paddingLeft: 24 }}
                  className={classNames(
                    'field-row',
                    classes.gridRoot,
                    field.id
                  )}>
                  {field.customized &&
                    <SourceToTargetMenu
                      data={field}
                      from={this.props.from}
                      to={this.props.to}
                      onChange={this.props.onChange}
                      parentField={this.props.parentField}
                      />}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    classes={{ item: classes.gridOdd }}
                    onClick={() =>
                      this.props.selectField(
                        this.props.itemId,
                        field.id,
                        'seofields'
                      )
                    }>
                    <TextField
                      label={
                        field.label && field.label.length >= 50 ? (
                          <Tooltip
                            title={field.label}
                            classes={{
                              arrow: classes.labelTooltipArrow,
                              popper: classes.labelTooltip,
                              tooltip: classes.noMaxWidth
                            }}
                            placement="bottom"
                            arrow>
                            <span>{truncate(field.label, 50)}</span>
                          </Tooltip>
                        ) : (
                          field.label
                        )
                      }
                      disabled
                      multiline={field.multiline}
                      rows={field.rows}
                      rowsMax={field.rows ? field.rows : 1}
                      fullWidth
                      value={field.source ? field.source : ''}
                      margin="none"
                      variant="outlined"
                      InputLabelProps={{
                        style: { pointerEvents: 'auto' },
                        shrink: true,
                        classes: {
                          root: classes.label
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classNames(
                            classes.textField,
                            classes.disabledField
                          ),
                          multiline: classes.textField,
                          input: classes.inputField,
                          inputMultiline: classes.inputField
                        }
                      }}
                      inputProps={{
                        dir: fromLanguage && fromLanguage.rtl ? 'rtl' : 'ltr'
                      }}
                      className={classNames(
                        classes.textField,
                        classes.targetField
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    classes={{ item: classes.gridEven }}
                    onClick={() =>
                      this.props.selectField(
                        this.props.itemId,
                        field.id,
                        'seofields'
                      )
                    }>
                    {field.customized
                      ? this.renderTranslationField(field)
                      : this.renderTranslationFieldWithTooltip(field)}
                  </Grid>
                </Grid>
                <Grid container direction="row-reverse">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    classes={{ item: classes.gridEven }}
                    >
                    {field.maxLength && field.target && (
                      <Typography
                        style={{display:"block",paddingLeft:12,marginTop:-12,marginBottom:field.id == 'page_description' ? 12 : 0}}
                        variant="caption"
                        className={classes.maxCharCount}>{`${
                        field.target.length
                      } of ${field.maxLength} characters used`}</Typography>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </div>
        </Collapse>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginLeft: '-24px',
    marginRight: '-24px',
    backgroundColor: '#fafafa'
  },
  gridRoot: {
    //backgroundColor: '#ccc !important',
    //paddingLeft: 16,
    position: 'relative !important',
    margin: '0 -12px !important',
    '&.image': {
      display: 'inline-block',
      width: 'auto',
      margin: '0 !important',
      paddingRight: 24
    }
  },
  gridOdd: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0 !important',
      '&>div': {
        marginBottom: 0,
        '& fieldset': {
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }
      },
      '& .ly-editor>div': {
        marginBottom: '0 !important',
        '& .redactor-box': {
          borderBottom: 'none',
          borderBottomLeftRadius: '0 !important',
          borderBottomRightRadius: '0 !important'
        }
      }
    }
  },
  textField: {
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
    '& [disabled]': {
      backgroundColor: '#fafafa !important',
      color: 'rgba(0, 0, 0, 0.38)'
    }
  },
  inputField: {
    padding: '12px 14px !important',
    fontSize: '14px !important',
    //boxSizing: 'border-box',
    height: 'auto !important'
  },
  targetField: {
    borderStyle: 'dashed !important'
  },
  disabledField: {
    backgroundColor: '#fafafa'
  },
  collapseContainer: {},
  divider: {
    //margin: '0 -24px',
  },
  headline: {
    padding: '8px 24px 8px 24px',
    //marginTop: -10,
    position: 'relative'
  },
  label: {
    color: '#555',
    cursor: 'pointer',
    padding: '12px 24px',
    fontSize: '1rem',
    transform: 'translate(-4px, -14px) scale(0.75) !important'
  },
  toggleIcon: {
    margin: '-2px 12px 0 0'
  },
  paper: {
    margin: '6px 24px 24px 24px !important',
    padding: '12px',
    '& h4': {
      fontSize: '1.2rem'
    },
    '& p': {
      color: '#555'
    }
  },
  cta: {
    margin: '0 24px 24px 24px !important',
    padding: '12px',
    textAlign: 'center',
    cursor: 'pointer',
    border: 'dashed 1px rgb(92, 107, 192)',
    backgroundColor: 'rgb(235, 237, 247)',
    '& p': {
      color: 'rgb(92, 107, 192)'
    },
    '& span': {
      color: 'rgb(92, 107, 192)'
    }
  },
  checkboxRoot: {
    margin: '-24px 0 0 0'
  },
  checkboxLabel: {
    margin: '-24px 0 0 0',
    color: '#757575',
    fontSize: 12
  },
  inheritFormControlLabel: {
    position: 'absolute',
    bottom: -2,
    right: 24
  }
})

const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  languages: languages
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAutoTranslations
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withTranslation()(SeoFields)))
)

import React, { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import withRouter from 'with-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dropzone, {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import { Typography, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DropzoneArea} from 'material-ui-dropzone';
import API from '../api/api';
import LoadingIndicator from '../components/LoadingIndicator';

class ImageField extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
      showEditDialog: false,
      selectedFile: null,
    }
  }

  componentDidMount() {
    
  }

  componentDidUpdate() {
    
  }

  handleClearImage = () => {
    this.setState({target: ''});

    this.props.onChange(null, {
      id: this.props.itemId,
      changed: this.props.source != '',
      initial: this.props.target,
      target: '',
    });
  }

  handleFileChange = files => { 
    this.setState({ selectedFile: files[0] }, () => {
      this.handleFileUpload();
    }); 
  }; 

  handleFileUpload = () => {
    const file = this.state.selectedFile;
    const formData = new FormData();
    formData.append('image', file);

    this.setState({isPending: true});
    API.uploadMedia(formData, response => {
      if(response.success == true) {
        this.props.onChange(null, {
          id: this.props.itemId,
          changed: true,
          initial: this.props.target,
          target: response.url
        });
        this.setState({target:response.url, isPending:false})
      } else {
        this.setState({target:this.props.source, isPending:false, showEditDialog:false})
      }
    });
  }



  handleOpenEditDialog = () => {
    this.setState({showEditDialog: true})
  }

  handleCloseEditDialog = () => {
    this.setState({showEditDialog: false})
  }

  handleFileError = (message, variant) => {
    console.log(message, variant);
  }

  handleFileRejected = (rejectedFiles) => {
    console.log(rejectedFiles);
  }

  renderFileUpload = () => {
    const { classes, readOnly } = this.props;

    return (
      <div className={classes.dropzoneWrapper}>
        <DropzoneArea
          dropzoneClass={classes.dropzone}
          dropzoneParagraphClass={classes.dropzoneText}
          dropzoneText={'Drop files here or click to upload'}
          showPreviews={false}
          showPreviewsInDropzone={false}
          showAlerts={true}
          filesLimit={1}
          maxFileSize={20000000}
          onChange={this.handleFileChange}
          onDropRejected={this.handleFileRejected}
          onAlert={this.handleFileError}
          acceptedFiles={['image/*']}
        />
        {/*
        <input type="file" onChange={this.handleFileChange} />
        <button onClick={this.handleFileUpload}>Upload!</button> 
        */}
      </div>
    )
  }

  render() {
    const { classes, readOnly, source, target } = this.props;
    var filepath = target ? target : source;
    var filename = filepath.substring(filepath.lastIndexOf("/")+1, filepath.indexOf("?"));
    return (
      <div className={classes.root}>
        {!this.state.isPending &&
          <div className={classes.imageContainer} onClick={this.handleOpenEditDialog}>
            <div className={classes.bgImage} 
              style={{
                backgroundImage: `url(${target ? target : source})`,
                opacity: target ? 1 : 0.5,
              }}
            ></div>
            <IconButton color="secondary" variant="contained" aria-label="Edit image translation" className={classNames('openDialogBtn', classes.openDialogBtn)} onClick={this.handleOpenEditDialog}>  
              {target !== '' && target !== source ? <CreateIcon /> : <AddIcon /> }
            </IconButton>
          </div>
        }
        <p className={classes.filenameText}>
          <small>{filename}</small>
        </p>

        <Dialog
          open={this.state.showEditDialog}
          onClose={this.handleCloseEditDialog}
          fullWidth={true}
          maxWidth={'sm'}
          //scroll="body"
        >
          <LoadingIndicator
            pending={this.state.isPending}
            withLogo
          />

          <DialogTitle id="alert-dialog-title" component="div">
            <Typography variant="h6" component="h6">Image translation</Typography>
            <IconButton aria-label="Delete" className={classes.closeBtn} onClick={this.handleCloseEditDialog}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {target !== '' && target !== source ?
              <React.Fragment>
                <img 
                  src={target} 
                  className={classes.image} 
                  style={{
                    opacity: readOnly ? .5 : 1,
                    cursor: readOnly ? 'no-drop' : 'normal',
                  }}
                />
                <Button color="secondary" variant="contained" aria-label="Delete" className={classes.clearBtn} onClick={this.handleClearImage}>
                  Clear Image <DeleteIcon style={{marginLeft: 6}} />
                </Button>
              </React.Fragment>
            :
              <React.Fragment>
                {!readOnly &&
                  this.renderFileUpload()
                }
              </React.Fragment>
            }
            {/*target !== '' && target !== source &&
              <Typography gutterBottom={false}>{filename}</Typography>
            */}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    textAlign: 'center',
    height: '100%',
    width: 224,
    display: 'flex',
    flex: '0 0 200px',
    flexDirection: 'column',
    paddingRight: 24,
    '&:hover .openDialogBtn': {
      opacity: '1 !important',
    }
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
    borderRadius: 4,
    height: 200,
    width: 200,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  bgImage: {
    height: 200,
    width: 200,
    backgroundSize: 'cover',
  },
  image: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
    borderRadius: 4,
    overflow: 'hidden',
  },
  infoLayer: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 6
  },
  clearBtn: {
    position: 'absolute',
    top: 12,
    right: 34,
    backgroundColor: 'rgb(211, 47, 47)',
    '&:hover': {
      backgroundColor: '#d32f2f',
    }
  },
  closeBtn: {
    position: 'absolute',
    top: -8,
    right: -8,
  },
  openDialogBtn: {
    position: 'absolute',
    top: '50%',
    margin: '-24px 0 0 0',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    opacity: 0,
    '&:hover': {
      opacity: '1 !important',
      backgroundColor: theme.palette.secondary.main,
    }
  },
  dropzoneWrapper: {
    height: '100%',
    width: '100%',
  },
  dropzone: {
    '& svg': {
      display: 'none'
    },
    height: '100%',
    width: '100%',
    padding: 16,
    margin: 0,
    border: 'dashed 2px #1a237e',
    background: '#ffffff',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzoneText: {
    fontSize: '15px',
    lineHeight: '1.5',
    color: 'rgba(0, 0, 0, 0.3)',
    fontStyle: 'italic'
  },
  filenameText: {
    margin: 0,
  },
  dialogContent: {
    padding: '0 24px 24px 24px',
    position: 'relative',
    overflow: 'visible',
  }
});

const mapStateToProps = ({ shop, themes }) => ({
  shop: shop,
  themes: themes.themes,
});

export default withRouter(connect(
  mapStateToProps,
  null
)(withStyles(styles)(ImageField)));
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DoneOutlined from "@material-ui/icons/DoneOutlined";

const DragHandle = SortableHandle(() => (
  <ListItemIcon className={'toggleEditModeBtn'} style={{marginRight: 0, minWidth: 'auto', cursor: 'ns-resize', alignItems: 'center', justifyContent: 'right'}}>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement(({ text, inEditingMode, iso_code, published, listIndex, onEditCustomName, classes, onEditModeChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  
  /*
  useEffect(() => {
  
    setIsEditing(inEditingMode);
    console.log(isEditing)
   }, [inEditingMode]);
*/
  const onChangeCustomName = (e) => {
    onEditCustomName({
      value: e.target.value, 
      listIndex
    })
  }

  const switchEditingMode = () => {
    onEditModeChange(!isEditing);

    setIsEditing(!isEditing);
  }
  
  
  return <ListItem ContainerComponent="div" dense disabled={!published} style={styles.sortableItem} className={classes.listItem}>
    <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${iso_code}.svg')`}} />
    
    {!isEditing ?
      <ListItemText primary={text} />
    :
      <TextField
        id="standard-bare"
        className={classes.textField}
        defaultValue={text}
        margin="dense"
        onChange={onChangeCustomName}
        fullWidth
      />
    }
    
    <ListItemSecondaryAction style={{display: 'flex', right: 0}}>
      {isEditing &&
        <IconButton onClick={switchEditingMode} style={styles.toggleEditModeBtn} className={'toggleEditModeBtn'}>
          <DoneOutlined />
        </IconButton>
      }

      {!isEditing &&
        <React.Fragment>
          <IconButton onClick={switchEditingMode} style={styles.toggleEditModeBtn} className={'toggleEditModeBtn'}>
            <EditOutlined />
          </IconButton>
          <DragHandle />
        </React.Fragment>
      }
    </ListItemSecondaryAction>
  </ListItem>
});

const SortableListContainer = SortableContainer(({ items, inEditingMode, onEditCustomName, onEditModeChange, classes }) => (
  <List component="div" className={classes.root} style={{padding: 0}}>
    {items.map(({ id, iso_code, name, custom_name, published }, index) => (
      <SortableItem key={`${id}-${index}`} index={index} 
        listIndex={index} 
        text={custom_name ? custom_name : name} 
        iso_code={iso_code} 
        published={published} 
        onEditCustomName={onEditCustomName} 
        onEditModeChange={onEditModeChange}
        inEditingMode={inEditingMode}
        classes={classes} 
      />
    ))}
  </List>
));

const SortableList = (props) => {
  const [items, setItems] = useState(props.data);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setItems(props.data);
  }, [props.data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(items, oldIndex, newIndex)
    setItems(newItems);
    props.onChange(newItems);
  };

  const onEditCustomName = (data) => {
    let newItems = items.slice();
    newItems[data.listIndex].custom_name = data.value;
    props.onChange(newItems);
  }

  const onEditModeChange = (isEditing) => {
    //alert(isEditing);
    setIsEditing(isEditing);
  }

  const { classes } = props;

  return (
    <SortableListContainer
      classes={classes}
      items={items}
      onSortEnd={onSortEnd}
      onEditCustomName={onEditCustomName}
      onEditModeChange={onEditModeChange}
      useDragHandle={true}
      inEditingMode={setIsEditing}
      lockAxis="y"
    />
  );
};

const styles = {
  root: {
    minWidth: 'auto !important',
    '& .list-item:hover': {
    }
  },
  listItem: {
    minWidth: 'auto !important',
    padding: '2px 0',
    '& .toggleEditModeBtn': {
      opacity: 0,
    },
    '&:hover button': {
    backgroundColor: 'red !important',
      opacity: 1,
    }
  },
  textField: {
    fontSize: 13,
    margin: 0,
    paddingLeft: 18
  },
  toggleEditModeBtn: {
    float: 'left', 
    padding: 2
  },

  '@global': {
    '&.list-item:hover': {
      backgroundColor: 'red !important',
    }
  }
}

export default withStyles(styles)(SortableList);



import React from 'react';
import { getCustomContents, saveCustomContents, createCustomContents, deleteCustomContents, exportCustomContents, importCustomContents, resetErrors } from '../../modules/customContents'
import { shop } from '../../modules/shop'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import FlexItemList from '../../components/FlexItemList';
import EmptyView from '../../components/EmptyView';
import LoadingIndicator from '../../components/LoadingIndicator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import OptionsMenu from '../../components/OptionsMenu';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: '#ccc',
    '&:hover': {
      backgroundColor: 'rgba(26, 35, 126, 0.5)',
      borderColor: "#ccc",
    }
  },
  emptyViewButton: {
    backgroundColor: '#fff'
  }
});

class Custom extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      plainTextData: [],
      addingNewCustomContent: false,
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      errorLockCollapse: false
    }
  }

  onDelete = (selected) => {
    this.props.deleteCustomContents(selected)
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        text: item.fields.find(x => x.id === 'text').target
      });
    })

    this.props.saveCustomContents(this.props.editLanguage, usedItems)
  }

  onResaveAll = () => {
    const items = this.state.data;
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        text: item.fields.find(x => x.id === 'text').target
      });
    })

    this.props.saveCustomContents(this.props.editLanguage, usedItems)
  }

  onCreate = items => {
    const usedItems = [];
    items.forEach(item => {
      if(item.text != '') {
        usedItems.push({
          text: item.text
        })
      }
    })
    
    this.props.createCustomContents(this.props.editLanguage, usedItems);
  }

  onImport = (e) => {
    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    if(fileType != 'mo' && fileType != 'csv') {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this.props.importCustomContents(formData, fileType, this.props.editLanguage)
  }

  onExport = (e) => {
    if(!this.props.customContents.export_po_download_url && !this.props.customContents.export_csv_download_url) {
      this.props.exportCustomContents(this.props.editLanguage);
    } else {
      this.setState({showDownloadDialog: true})
    }
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  componentDidMount() {
    this.props.getCustomContents(this.props.editLanguage);

    if(this.props.customContents.export_po_download_url || this.props.customContents.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.getCustomContents(newLanguage);
      return
    }

    if(this.props.customContents.import_custom_contents_pending === true && newProps.customContents.import_custom_contents_pending === false) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }

    const createWasPending = this.props.customContents.create_custom_contents_pending;
    const createIsPending = newProps.customContents.create_custom_contents_pending;
    if(createWasPending === true && createIsPending === false) {
      this.setState({addingNewCustomContent: false});
    }

    const newData = [];
    const newPlainTextData = [];
    for(let key in newProps.customContents.custom_contents) {
      const item = newProps.customContents.custom_contents[key];
      newData.push({
        type: 'custom_content',
        id: parseInt(key),
        progress: item.progress,
        title: item.source.text,
        info: '',
        fields: [{
          id: 'text',
          label: '',
          source: item.source.text,
          target: item.target.text,
          multiline: true, 
          rows: 7
        }]
      })
      newPlainTextData.push(item.source.text)
    }

    this.setState({data:newData.reverse(), plainTextData: newPlainTextData});
  }

  onClickAddButton() {
    this.setState({addingNewCustomContent: true})
  }

  onClickCancelButton() {
    this.setState({addingNewCustomContent: false}) 
  }

  render() {
    const { t, classes } = this.props;

    if(this.props.customContents.get_custom_contents_pending === true || (this.props.customContents.error && this.props.customContents.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.custom.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="8"/>
        <LoadingIndicator
          pending={this.props.customContents.get_custom_contents_pending || this.props.customContents.save_custom_contents_pending || this.props.customContents.create_custom_contents_pending || this.props.customContents.delete_custom_contents_pending}
          progress={this.props.customContents.progress}
          error={this.props.customContents.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.custom.title')}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          onResaveAll={this.onResaveAll}
        />
               
        {this.state.addingNewCustomContent ? (
        <Button 
          variant="outlined" 
          color="primary"
          style={{ float: 'right' }} 
          size="small" className={classes.button}
          onClick={(event) => {this.onClickCancelButton(event)}}>
          Cancel
        </Button>
          ) : (
        <Button 
          variant="outlined" 
          color="primary"
          style={{ float: 'right' }} 
          size="small" className={classes.button}
          onClick={(event) => {this.onClickAddButton(event)}}>
          {t('customContents.addNewCustomContent')}
        </Button>
        )}
      </Typography>
      {this.state.addingNewCustomContent ? (
        <div>
        <Divider className="divider-white" />
        <FlexItemList
          data={this.state.plainTextData}
          onSave={this.onCreate}
          isPending={this.props.customContents.create_custom_contents_pending}
        />
        </div>
        ) : (null
      )}
      <Divider className="divider-white" />

      <ItemsList 
        type="custom"
        pagination={true}
        isPending={this.props.customContents.save_custom_contents_pending || this.props.customContents.create_custom_contents_pending || this.props.customContents.delete_custom_contents_pending || this.props.customContents.import_custom_contents_pending}
        isSaving={this.props.customContents.save_custom_contents_pending}
        deletable={true}
        onDelete={this.onDelete}
        onSave={this.onSave}
        data={this.state.data}
        allowAutoTranslation={true}
        allowFieldFiltering={true}
        hideSorting={true}
        error={this.props.customContents.error}
        errorLockCollapse={this.state.errorLockCollapse}
        emptyMessage={
          <EmptyView
            title="No custom content created yet"
            description="You only should create custom content after you haved translated everything without using this feature and some translations are still not showing up. After you have added and translated custom content you always should check if your shop is still behaving as it should! If your shop is not behaving as expected after you have translated custom content then please remove it and drop us a mail!"
            bottom={
              <Button
                variant="outlined" 
                size="small"
                className={classes.emptyViewButton}
                onClick={(event) => {this.onClickAddButton(event)}}>
                {t('customContents.addNewCustomContent')}
              </Button>
            }
          />
        }
      />
      <LoadingIndicator
        pending={this.props.customContents.get_custom_contents_pending || this.props.customContents.save_custom_contents_pending || this.props.customContents.create_custom_contents_pending || this.props.customContents.delete_custom_contents_pending || this.props.customContents.import_custom_contents_pending}
        loadingType={(this.props.customContents.save_custom_contents_pending || this.props.customContents.create_custom_contents_pending) ? 'saving' : 'loading'}
        progress={this.props.customContents.progress}
        error={this.props.customContents.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        showDownloadDialog={this.state.showDownloadDialog}
        export_po_download_url={this.props.customContents.export_po_download_url}
        export_csv_download_url={this.props.customContents.export_csv_download_url}
      />
    </React.Fragment>  
  }
}

const mapStateToProps = (state) => ({
  customContents: state.customContents,
  editLanguage: state.shop.shop.edit_language
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomContents,
      saveCustomContents,
      createCustomContents,
      deleteCustomContents,
      exportCustomContents,
      importCustomContents,
      resetErrors
    },
    dispatch
  )


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Custom))))
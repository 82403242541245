import React from 'react';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import AddIcon from '@material-ui/icons/Add';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import { SportsRugbySharp } from '@material-ui/icons';


class CollapsableSection extends React.Component {
  intersectionObs = null;
  targetIsIntersecting = null;
  backdropDelay = null;
  tempScroll = 0;

  constructor(props) {
    super(props);
     
    this.state = {
      open: false,
      fixedNotice: false,
      showBackdrop: false,
    }
  }

  componentDidMount() {
    if(this.props.deeplink) {
      if(window.location.hash === `#${this.props.deeplink}`) {
        if(!this.props.initialOpen && !this.props.pending) {
          this.handleToggleOpen()
        }
        if(!this.props.initialOpen && this.props.pending) {
          this.openWhenLoaded = true;
          setTimeout(this.scrollToTop, 100);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if(window.mainFrameRef) window.mainFrameRef.removeEventListener('scroll', this._noScroll);
  }

  componentWillReceiveProps(newProps, newState) {
    if(this.props.pending && newProps.pending === false && this.openWhenLoaded) {
      this.openWhenLoaded = false;
      this.handleToggleOpen()
    }
    //if(newProps.noticeType === 'error') {
      if(this.props.noticeShow === false && newProps.noticeShow === true) {
        const node = ReactDOM.findDOMNode(this).getElementsByClassName('collapsable-section');
        this.intersectionObs = new IntersectionObserver(this.intersectionCallback, { 
          rootMargin: '0px',
          threshold: [0] 
        });
        this.intersectionObs.observe(node[0]);
      }
      if(this.props.noticeShow === true && newProps.noticeShow === false) {
        if(this.intersectionObs) {
          this.intersectionObs.disconnect();
        }
        this.setState({ fixedNotice: false });
      }
    //}
    if(this.props.noticeShow === true && newProps.noticeShow === false) {
      setTimeout(() => this.scrollToTop(true), 1);
    }
    if(this.props.scrollToTop !== newProps.scrollToTop) {
      setTimeout(() => this.scrollToTop(true), 1);
    }
  }

  intersectionCallback = (intersections) => {
    intersections.forEach((intersection) => {
      //console.log(intersection)

      if(this.targetIsIntersecting === null) {
        this.targetIsIntersecting = intersection.isIntersecting ? true : false;
      }
      if(this.targetIsIntersecting === false && intersection.isIntersecting === true) {
        this.targetIsIntersecting = true;
        this.setState({ fixedNotice: false });
      } 
      else if(this.targetIsIntersecting === true && intersection.isIntersecting === false ||
              this.targetIsIntersecting === false && intersection.isIntersecting === false) {
        this.targetIsIntersecting = false;
        this.setState({ fixedNotice: true });
      }
    });
  }

  onResize = () => {
    this.scrollToTop(true);
  }

  handleToggleOpen = () => {
    window.location.hash = !this.state.open ? `#${this.props.deeplink}` : '';
    this.setState({ open: !this.state.open }, () => {
      if(this.state.open) {
        if(this.props.pinSectionOnOpen) {
          this.blockScroll();
          this.backdropDelay = setTimeout(() => {
            this.setState({ showBackdrop: true });
          }, 500);          
        }
        if(this.props.onOpened) {
          this.props.onOpened();
        }
      } else {
        this.allowScroll();
        if(this.backdropDelay) {
          clearTimeout(this.backdropDelay)
        }
        this.setState({ showBackdrop: false });
        if(this.props.onClosed) {
          this.props.onClosed();
        }
      }
    });
  }

  handleClose = () => {
    this.allowScroll();
    this.setState({ open: false, showBackdrop: false });
  }

  handleClosed = () => {
    if(this.props.onClosed) {
      this.props.onClosed();
    }
  }

  handleClickNotice = () => {
    window.location.hash = !this.state.open ? `#${this.props.deeplink}` : '';
    this.setState({ open: true });
    this.scrollToTop();
  }

  handleCtaAction = () => {

  }

  _noScroll = () => {
    //window.mainFrameRef.scrollTo(0, this.tempScroll);
    this.scrollToTop(true);
  }
  blockScroll = () => {
    setTimeout(() => {
      //window.mainFrameRef.addEventListener('scroll', this._noScroll);
      window.mainFrameRef.style.setProperty('overflow', 'hidden', 'important');
      window.addEventListener('resize', this.onResize);
    }, 750);
  }
  allowScroll = () => {
    //window.mainFrameRef.removeEventListener('scroll', this._noScroll);
    window.mainFrameRef.style.setProperty('overflow', 'auto', 'important');
    window.removeEventListener('resize', this.onResize);
  }

  setRootRef = (ref) => {
    this.rootRef = ref;
    if(ReactDOM.findDOMNode(this.rootRef)) {
      this.boundings = ReactDOM.findDOMNode(this.rootRef).getBoundingClientRect();
    }
  }

  scroll = null;
  scrollToTop = (jump = false) => {
    let offset = (this.props.pinSectionOnOpen && this.state.open) ? 87 : 63;
    let noticeOffset = this.props.noticeShow ? 44 : 0;
    if(ReactDOM.findDOMNode(this.rootRef)) {
      this.boundings = ReactDOM.findDOMNode(this.rootRef).getBoundingClientRect();
      if(window.mainFrameRef) {
        this.scroll = window.mainFrameRef.scrollTop;
        if(jump) {
          window.mainFrameRef.scroll({top: this.boundings.top + this.scroll - offset + noticeOffset });
        } else {
          window.mainFrameRef.scroll({top: this.boundings.top + this.scroll - offset + noticeOffset, behavior: 'smooth' });
        }
        this.tempScroll = this.boundings.top + this.scroll - offset + noticeOffset;
      }
    }
  };

  render() {
    /*
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme)) {
      return null
    }
    */

    const { 
      classes, 
      title,
      ctaBtnProps,
      ctaBtnHide,
      ctaBtnText,
      ctaBtnAction,
      openBtnHide,
      openBtnText,
      cancelBtnHide,
      cancelBtnText,
      noticeShow,
      noticeType,
      noticeText,
      content, 
      containsError,
      pending,
      initialOpen,
      pinSectionOnOpen,
    } = this.props;

    const { 
      open,
      fixedNotice,
      showBackdrop,
    } = this.state;

    return(
      <div ref={this.setRootRef} className={classNames(classes.root, (pinSectionOnOpen && open) ? 'pinned' : null)}>
        <Fade in={showBackdrop} timeout={{enter: 1000}} unmountOnExit>
          <div className={classes.backdrop} onClick={this.handleClose}></div>
        </Fade>
        <Collapse in={noticeShow} onClick={!pending ? this.handleToggleOpen : null}>
          <div>
            {fixedNotice &&
              <div style={{height: 42.05, position: 'relative'}}></div>          
            }
            <div className={classNames('sticky-notice', classes.notice, classes[noticeType], fixedNotice ? 'is-fixed' : null)} onClick={ fixedNotice ? this.handleClickNotice : null} style={{ cursor: fixedNotice ? 'pointer' : 'default'}}>
              <Toolbar className={classNames('toolbar')}>
                <p>
                  {noticeType === 'error' ? <ErrorOutline className={classes.noticeIcon} /> : <InfoOutlinedIcon className={classes.noticeIcon} />} 
                  {noticeText}
                </p>
              </Toolbar>
            </div>           
          </div>
        </Collapse>
        <div variant="body1" className={classNames('collapsable-section', classes.contentContainer, noticeShow && classes.noticed)}>
          <Paper className={classes.paper} elevation={(pinSectionOnOpen && open) ? 10 : 1}>
            <Toolbar className={classNames('toolbar')}>
              <h3 onClick={!pending ? this.handleToggleOpen : null} className={classes.collapseToggler} style={{ opacity: open ? 1 : 0.65 }}>
                {open ? <RemoveOutlined className={classes.icon} /> : <AddIcon className={classes.icon} />}
                {title}
              </h3>
              {open ? 
                <div style={{ position: 'absolute', right: 16 }} >
                  {!cancelBtnHide &&
                    <Button
                      variant="outlined" 
                      onClick={this.handleClose}
                      disabled={pending ? true : false}
                      className={classes.button}>
                      {cancelBtnText}
                    </Button>
                  }
                  {!ctaBtnHide &&
                    <Button
                      variant="contained" 
                      color="secondary"
                      onClick={ctaBtnAction}
                      disabled={pending ? true : false}
                      className={classes.button}
                      {...ctaBtnProps}
                    >
                      {ctaBtnText}
                      {pending &&
                        <CircularProgress color="primary" size={16} style={{marginLeft: 10}} />
                      }
                    </Button>
                  }
                </div>
              :
                <div style={{ position: 'absolute', right: 16 }} >
                  {!pending ?
                    <Button 
                      variant="outlined" 
                      color="primary"
                      onClick={this.handleToggleOpen}
                      className={classes.button}>
                      {openBtnText}
                    </Button>
                  :
                    <CircularProgress size={24} style={{marginRight: 10}} />
                  }
                </div>
              }
            </Toolbar> 
            <Collapse 
              onEnter={this.scrollToTop}
              onEntered={this.scrollToTop}
              onExited={this.handleClosed}
              //className={classNames('container')} 
              in={open || initialOpen}
            >
              <Divider /> 
              {content}
            </Collapse>
          </Paper>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    '&.pinned .collapsable-section': {
      position: 'relative',
      zIndex: 1000,
    }
  },
  backdrop: {
    position: 'fixed', 
    zIndex: 900, 
    top: 0, 
    bottom: 0, 
    left: 0, 
    right: 0, 
    backgroundColor: 'rgba(0, 0, 0, .25)',
    cursor: 'pointer',
  },
  paper: {
    width: '100%',
    margin: 0,
    marginBottom: theme.spacing(3),
    overflow: 'hidden',
  },
  collapseToggler: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400 !important',
  },
  icon: {
    marginRight: theme.spacing(2),
    opacity: .5,
  },
  contentContainer: {
    //marginBottom: theme.spacing(2),
  },
  noticed: {
    borderRadius: '0 0 4px 4px',
  },
  card: {
    //maxWidth: 400,
  },
  media: {
    width: '100%',
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#ccc',
  },
  actions: {
    display: 'flex',
  },
  title: {
    fontSize: 16,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardActions: {
    padding: '0px 12px 14px 12px'
  },
  cardHeader: {
    alignItems: 'baseline',
    paddingBottom: 0
  },
  button: {
    marginLeft: 10,
  },
  notice: {
    marginBottom: 0,
    borderRadius: '4px 4px 0 0',
    overflow: 'hidden',
    border: 'solid 1px rgba(0,0,0,.05)',
    '& .toolbar': {
      minHeight: 'auto !important',
      padding: '2px 16px',
    },
    '& p': {
      margin: '8px !important',
    },
    '& svg': { 
      fontSize: 24, 
      marginTop: -2, 
      marginRight: 8, 
    },
    '&.is-fixed': {
      position: 'fixed',
      bottom: 0,
      left: 'calc(240px + 24px)',
      right: 24
    }
  },
  error: {
    backgroundColor: '#ffebee',
    color: '#f44336',
  },
  info: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.9),
    //color: '#f57c00',
    //backgroundColor: '#fff3e0',
  },
  sidebarLogo: {
    position: 'absolute',
    left: 0,
    top: 15,
    width: 240,
    textAlign: 'center',
  },
});

CollapsableSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  themes: state.themes,
  editTheme: state.themes.edit_theme,
  master_loading: state.master_loading,
  progress: state.progress
})

export default withRouter(connect(
  mapStateToProps
)(withStyles(styles)(CollapsableSection)))

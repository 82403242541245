import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_PAGES_PENDING = 'SYNC_PAGES_PENDING'
const SYNC_PAGES_PROGRESS = 'SYNC_PAGES_PROGRESS'
const SYNC_PAGES_RESPONSE = 'SYNC_PAGES_RESPONSE'

const SYNC_PAGE_PENDING = 'SYNC_PAGE_PENDING'
const SYNC_PAGE_RESPONSE = 'SYNC_PAGE_RESPONSE'

const SAVE_PAGES_PENDING = 'SAVE_PAGES_PENDING'
const SAVE_PAGES_PROGRESS = 'SAVE_PAGES_PROGRESS'
const SAVE_PAGES_RESPONSE = 'SAVE_PAGES_RESPONSE'

const SYNC_PAGE_METAFIELDS_PENDING = 'SYNC_PAGE_METAFIELDS_PENDING'
const SYNC_PAGE_METAFIELDS_PROGRESS = 'SYNC_PAGE_METAFIELDS_PROGRESS'
const SYNC_PAGE_METAFIELDS_RESPONSE = 'SYNC_PAGE_METAFIELDS_RESPONSE'

const CREATE_PAGE_CUSTOM_CONTENTS_PENDING = 'CREATE_PAGE_CUSTOM_CONTENTS_PENDING'
const CREATE_PAGE_CUSTOM_CONTENTS_PROGRESS = 'CREATE_PAGE_CUSTOM_CONTENTS_PROGRESS'
const CREATE_PAGE_CUSTOM_CONTENTS_RESPONSE = 'CREATE_PAGE_CUSTOM_CONTENTS_RESPONSE'
const DELETE_PAGE_CUSTOM_CONTENT_PENDING = 'DELETE_PAGE_CUSTOM_CONTENT_PENDING'
const DELETE_PAGE_CUSTOM_CONTENT_PROGRESS = 'DELETE_PAGE_CUSTOM_CONTENT_PROGRESS'
const DELETE_PAGE_CUSTOM_CONTENT_RESPONSE = 'DELETE_PAGE_CUSTOM_CONTENT_RESPONSE'

const EXPORT_PAGES_PENDING = 'EXPORT_PAGES_PENDING'
const EXPORT_PAGES_PROGRESS = 'EXPORT_PAGES_PROGRESS'
const EXPORT_PAGES_RESPONSE = 'EXPORT_PAGES_RESPONSE'

const IMPORT_PAGES_PENDING = 'IMPORT_PAGES_PENDING'
const IMPORT_PAGES_PROGRESS = 'IMPORT_PAGES_PROGRESS'
const IMPORT_PAGES_RESPONSE = 'IMPORT_PAGES_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_pages_pending: true,
  sync_page_pending: false,
  sync_page_metafields_pending: false,
  create_page_custom_contents_pending: false,
  delete_page_custom_content_pending: false,
  save_pages_pending: false,
  export_pages_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_pages_pending: false,
  imported_entries: 0,
  pages: [],
  progress: 0,
  error: false,
}

function validatePage(data, origin) {
  if (typeof data.general_errors !== 'undefined' && typeof data.general_errors.errors !== 'undefined') {
    data.general_errors.errors.forEach(item => {
      switch(item.object_key) {
        case 'handle' :
          data.target.handle = origin.target.handle;
          break;
      }
    })
  }
  return data;
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case SYNC_PAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_pages_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_PAGES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PAGES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_pages_pending: false,
          error: false,
          pages: action.response.pages
        }
      } else {
        return {
          ...state,
          sync_pages_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case SYNC_PAGE_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_page_pending: true,
        error: false,
      }
    }
    case SYNC_PAGE_RESPONSE: {
      if(action.response.success === true) {
        let newPages = state.pages.slice()
        let pageToUpdate = newPages.find(x => x.id == action.response.page.id)
        
        pageToUpdate.synchronized = true;

        pageToUpdate.progress = action.response.page.progress

        pageToUpdate.source.title = action.response.page.source.title
        pageToUpdate.source.body_html = action.response.page.source.body_html
        pageToUpdate.source.handle = action.response.page.source.handle
        pageToUpdate.source.page_title = action.response.page.source.page_title
        pageToUpdate.source.page_description = action.response.page.source.page_description
        
        pageToUpdate.target.title = action.response.page.target.title
        pageToUpdate.target.body_html = action.response.page.target.body_html
        pageToUpdate.target.handle = action.response.page.target.handle
        pageToUpdate.target.page_title = action.response.page.target.page_title
        pageToUpdate.target.page_description = action.response.page.target.page_description

        pageToUpdate.custom_contents = action.response.page.custom_contents.slice()
        pageToUpdate.customSeo = action.response.page.custom_seo
        
        return {
          ...state,
          pages: newPages,
          sync_page_pending: false,
          error: false,
        }
      } else {
        return {
          ...state,
          sync_page_pending: false,
          sync_page_error: action.response,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          }
        }
      }
    }
    case SAVE_PAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        save_pages_pending: true,
        error: false,
      }
    }
    case SAVE_PAGES_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_PAGES_RESPONSE: {
      let error = false;
      let newPages = state.pages.slice();
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let newPage = newPages.find(x => x.id == response.page.id)
          let page = validatePage(response.page,newPage)
          newPage.progress = page.progress
          // commented out so source is not overwritten if backend returns insufficient data
          // newPage.source = {...page.source}
          newPage.target = {...page.target}
          newPage.metafields = page.metafields.slice()
          newPage.custom_contents = page.custom_contents.slice()
          newPage.errors = page.general_errors.errors
        }
      })
      return {
        ...state,
        pages: newPages,
        save_pages_pending: false,
        error: error
      }
    }
    case SYNC_PAGE_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_page_metafields_pending: true,
        error: false,
      }
    }
    case SYNC_PAGE_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_PAGE_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newPages = state.pages.slice()
        let pageToUpdate = newPages.find(x => x.id == action.response.page_id)
        
        pageToUpdate.metafields_synchronized = true;
        pageToUpdate.metafields = action.response.metafields.slice()

        return {
          ...state,
          sync_page_metafields_pending: false,
          error: false,
          pages: newPages,
        }
      } else {
        return {
          ...state,
          sync_page_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 2,
          }
        }
      }
      return {
        ...state,
        sync_page_metafields_pending: false
      }
    }
    case CREATE_PAGE_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_page_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_PAGE_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_PAGE_CUSTOM_CONTENTS_RESPONSE: {
      let newPages = state.pages.slice()
      let error = false;

      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let pageToUpdate = newPages.find(x => x.id == response.page_id)
          pageToUpdate.custom_contents.push({
            id: response.custom_content.id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          });
        }
      }

      return {
        ...state,
        pages: newPages,
        create_page_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_PAGE_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_page_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_PAGE_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_PAGE_CUSTOM_CONTENT_RESPONSE: {
      let newPages = state.pages.slice()
      let error = false;

      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        let pageToUpdate = newPages.find(x => x.id == action.response.page_id)
        pageToUpdate.custom_contents = pageToUpdate.custom_contents.filter(x => x.id != action.response.id)
      }

      return {
        ...state,
        pages: newPages,
        delete_page_custom_content_pending: false,
        error: error
      }
    }
    case EXPORT_PAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        error: false,
        export_pages_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null
      }
    }
    case EXPORT_PAGES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_PAGES_RESPONSE: {
      //console.log(_exportDownloadLinkDecorator(action.response.csv_download_url, action.options))
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_pages_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),
        }
      } else {
        return {
          ...state,
          progress: 100,
          export_pages_pending: false,
          error: {
            message: action.response,
            action: action.action,
          }
        }
      }
    }
    case IMPORT_PAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        error: false,
        import_pages_pending: true,
        imported_entries: 0
      }
    }
    case IMPORT_PAGES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_PAGES_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_pages_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_pages_pending: false,
          imported_entries: importedEntries,
          error:{
            message: action.response,
            action: action.action,
          }
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_pages_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}




// ACTIONS CREATORS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncPages = (language, sync_reset, callback) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PAGES_PENDING
    })

    API.syncPages(
      language,
      progress => {
        dispatch({
          type: SYNC_PAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PAGES_RESPONSE,
          response: response,
          action: () => syncPages(language, sync_reset, callback),
        });
        if(callback) {
          callback(response);
        }
      },
      sync_reset
    )
  }
}

export const syncPage = (pageId, languageId) => {
  return dispatch => {
    dispatch({
      type: SYNC_PAGE_PENDING
    })

    API.syncPage(
      pageId,
      languageId,
      response => {
        dispatch({
          type: SYNC_PAGE_RESPONSE,
          response: response,
          action: () => syncPage(pageId, languageId),
        })
      }
    )
  }
}

export const savePages = (language, items, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_PAGES_PENDING
    })

    API.savePages(
      language,
      items,
      force,
      progress => {
        dispatch({
          type: SAVE_PAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_PAGES_RESPONSE,
          response: response,
          items: items,
          action: () => savePages(language, items, force),
        })
      })
  }
}

export const syncPageMetafields = (page, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_PAGE_METAFIELDS_PENDING
    })

    API.syncPageMetafields(
      page,
      language,
      progress => {
        dispatch({
          type: SYNC_PAGE_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_PAGE_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncPageMetafields(page, language),
        }
      )
    })
  }
}

export const createPageCustomContents = (page, language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_PAGE_CUSTOM_CONTENTS_PENDING
    })

    API.createPageCustomContents(
      page,
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_PAGE_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_PAGE_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createPageCustomContents(page, language, items)
        })
      })
  }
}

export const deletePageCustomContent = (page, ids) => {
  return dispatch => {
    dispatch({
      type: DELETE_PAGE_CUSTOM_CONTENT_PENDING
    })

    API.deletePageCustomContent(
      page,
      ids,
      progress => {
        dispatch({
          type: DELETE_PAGE_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_PAGE_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deletePageCustomContent(page, ids)
        })
      })
  }
}

export const exportPages = (language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_PAGES_PENDING
    })

    API.exportPages(
      language, 
      options,
      progress => {
        dispatch({
          type: EXPORT_PAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_PAGES_RESPONSE,
          response: response,
          options: options,
          action: () => exportPages(language, options),
        }
      )
    })
  }
}

export const importPages = (file, fileType, language, options) => {
  return dispatch => {
    dispatch({
      type: IMPORT_PAGES_PENDING
    })

    API.importPages(
      file,
      fileType,
      language, 
      options,
      progress => {
        dispatch({
          type: IMPORT_PAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_PAGES_RESPONSE,
          response: response,
          action: () => importPages(file, fileType, language, options),
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}
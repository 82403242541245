import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import { WindowScroller, CellMeasurer, CellMeasurerCache, List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import CircularProgress from '@material-ui/core/CircularProgress';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Sticky from 'react-sticky-el';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import RichTextEditor from '../components/RichTextEditor'
import RechargePlans from '../components/RechargePlans'
import ImageField from './ImageField'
import CollapsableFields from './CollapsableFields'
import VariantsFields from './VariantsFields';
import ItemsListSkeleton from './ItemsListSkeleton';
import { Button } from '@material-ui/core';
import { updateAutoTranslations } from '../modules/shop'
import API from '../api/api';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import DontAskAgainDialog from './DontAskAgainDialog.js';
import TranslationField from './TranslationField.js';

const REACT_APP_ENABLE_AUTO_TRANSLATIONS = window.loadEnv("REACT_APP_ENABLE_AUTO_TRANSLATIONS", process.env.REACT_APP_ENABLE_AUTO_TRANSLATIONS);


function filterFields(fields, filterGroup, filterQuery) {
  if(!filterGroup) var filterGroup = 'all';
  if(!filterQuery) var filterQuery = '';

  let returnedArray =  Object.values(fields).filter(x => {
    if(!x.source) {
      return true;
    } else {
      // Filter for field-groups like "outdated"
      if(filterGroup && filterGroup === 'outdated') {
        if(x.outdated && x.outdated === true) {
          return true;
        } else {
          return false;
        }
      }

      //
      //
      // Add new conditions here
      // ...

      // show all items
      if(filterGroup && filterGroup === 'all') {
        return true;
      }
    }
  });

  returnedArray = returnedArray.filter(x => {
    if(!x.source) {
      return true;
    } else {

      // Filter in this fields:
      const fields = [
        x.source,
        x.target ? x.target : null,
        window.location.href.indexOf('/theme') > -1 ? x.label : null,
      ];
      if(fields.join().toLowerCase().indexOf(filterQuery.toLowerCase()) > -1) {
        return true;
      }            
    }
  });
  
  return returnedArray;
} 



class TranslationFields extends React.Component {
  overflowIndex = -1;


  constructor(props) {
    super(props);


    const cache = new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
      //keyMapper: () => 1,
    });
    this.cache = cache;
    
    this.state = {
      data: props.data,
      loadingTranslations: false,
      loadingTranslationsError: false,
      canAutoTranslate: true,
      //forceUpdate: props.forceUpdate ? props.forceUpdate : false,
      forceUpdate: props.forceUpdate,
      filterQuery: '',
      showRechargePlans: false,
      showNotEnoughWordsDialog: false,
      showMuchHtmlNotice: false,
      scrollToIndex: props.scrollToIndex ? props.scrollToIndex : -1,
    }
  }

  componentDidMount = () => {

    this.setState({
      filterQuery: this.props.filterQuery ? this.props.filterQuery : '',
    });

    this.resizeList();  
    
    setTimeout(() => {
      if(this.windowScrollerRef) {
        this.windowScrollerRef.updatePosition();   
      }
    }, 1000);
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({
      data: newProps.data,
      forceUpdate: newProps.forceUpdate ? newProps.forceUpdate : this.state.forceUpdate,
      //forceUpdate: newProps.forceUpdate,
      filterQuery: newProps.filterQuery ? newProps.filterQuery : '',
    })

    if(this.props.triggerClearAllTranslations !== newProps.triggerClearAllTranslations) {
      this.handleClearAllTranslations();
    }

    if(this.props.forceUpdate !== newProps.forceUpdate) {
      
    }

    if(this.props.scrollToIndex !== newProps.scrollToIndex) {
      this.setState({scrollToIndex: newProps.scrollToIndex})
    }

    const currentLanguage = this.props.shop.shop.edit_language;
    const newLanguage = newProps.shop.shop.edit_language;
    if(currentLanguage != newLanguage) {
      this.resizeList(); 
    }
  }

  componentDidUpdate(prevProps) {
    this.resizeList();
  }

  handleClearAllTranslations = () => {
    let newData = this.state.data.slice();
    newData.forEach((item, i, a) => {
      newData[i].initial = this.state.data[i].target;
      newData[i].target = '';
    });
    this.setState({
      data: newData,
      forceUpdate: true
    }, () => {
      this.setState({
        forceUpdate: false
      });      
    });
  }

  openRechargePlans = () => {
    this.setState({
      showRechargePlans: true
    })
  }

  handleContinueTranslation = () => {
    this.bulkTranslateSection(this.overflowIndex);
    this.closeNotEnoughWordsDialog();
  }

  bulkTranslateSection = (maxIndex) => {
    this.overflowIndex = -1;
    // Collect all section source strings in an array
    let stringsArr = {};
    this.state.data.map((field, index) => {
      if(field.type === 'subfields') {
        field.fields.map(subfield => {
          if(subfield.source !== '' && subfield.target === '') stringsArr[subfield.id] = subfield.source.toString();
        });
      } else {
        if(field.source !== '' && field.target === '') stringsArr[field.id] = field.source.toString();
      }     
    });

    // Handle events that block the process
    let wordsToTranslateNum = this.countWords(stringsArr, true);
    if(maxIndex === null) {
      let hasMuchHtml = this.checkHtmlAmount(stringsArr);
      let outQuota = wordsToTranslateNum > this.props.shop.shop.auto_translations.available_words;
      if(hasMuchHtml || outQuota) {
        this.setState({
          loadingTranslations: false,
          showNotEnoughWordsDialog: outQuota,
          showMuchHtmlNotice: hasMuchHtml,
        });
        return null;
      }
    }

    // Handle overflow
    let newStringsArr = {};
    Object.keys(stringsArr).map((key, currIndex) => {
      if(currIndex <= this.overflowIndex) {
        newStringsArr[key] = stringsArr[key];
      }
    });
    stringsArr = newStringsArr;
    if(Object.keys(stringsArr).length === 0) {
      return null;
    }


    // Call API Servie and manage response
    this.setState({
      loadingTranslations: true,
    })
    API.autoTranslateStrings(this.props.from, this.props.to, stringsArr, (res) => {
      if(res.success === false || !res.auto_translations ) {
        this.setState({ 
          loadingTranslations: false, 
          loadingTranslationsError: true, 
        });
        return null;
      }

      this.props.updateAutoTranslations(
        res.auto_translations.total_used_words,
        res.auto_translations.available_words,
      )

      // Update res of all section target fields
      let newData = this.state.data.slice();

      Object.keys(stringsArr).forEach((key, i) => {
        if(newData.find(x => x.id == key)) {
          newData.find(x => x.id == key).initial = newData.find(x => x.id == key).target;
          newData.find(x => x.id == key).target = res.Strings[key];
        } else {
          if(newData.find(x => x.type == 'subfields')) {
            newData.filter(x => x.type == 'subfields').forEach((item, p) => {
              if(item.fields.find(x => x.id == key)) {
                //console.log(item.fields.find(x => x.id == key))
                item.fields.find(x => x.id == key).initial = item.fields.find(x => x.id == key).target;
                item.fields.find(x => x.id == key).target = res.Strings[key];
                item.fields.find(x => x.id == key).parentField = item.id;
              }
            });
          }
        }
      });

      this.setState({
        data: newData,
        forceUpdate: true,
        loadingTranslations: false,
        loadingTranslationsError: false, 
      }, () => {
        this.setState({forceUpdate: false });
      });
      setTimeout(() => {
        this.props.onSave();
      }, 100);
    });
  }

  countWords = (arr, calcOverflow) => {
    let deleteEmptySliceValues = function(inputArray) {
      var r = [];
      inputArray.forEach(function(item) {
        if (item !== "") {
          r.push(item);
        }
      });
      return r
    }

    let sum = Object.values(arr).reduce((pv, cv, ci) => {
      let singleSourceString = cv.toString();
      let charCount = singleSourceString.length;
      let calc_words_by_chars = charCount / 6;
      singleSourceString = singleSourceString.replace(/-/g, " ");
      singleSourceString = singleSourceString.replace(/</g, " ");
      singleSourceString = singleSourceString.replace(/>/g, " ");
      singleSourceString = singleSourceString.replace(/\"/g, " ");
      singleSourceString = singleSourceString.replace(/\n/g, " ");
      let wordSlice = singleSourceString.split(" ");
      wordSlice = deleteEmptySliceValues(wordSlice);

      let diff = calc_words_by_chars - wordSlice.length;
      if (diff < 0) diff = diff * -1;
      let diffPercent = (diff * 100) / wordSlice.length
      let price_words = wordSlice.length;
      if (diffPercent > 10 && calc_words_by_chars > wordSlice.length) {
          price_words = calc_words_by_chars;
      }
      let totalWords = pv + parseInt(price_words);

      if(calcOverflow && totalWords <= this.props.shop.shop.auto_translations.available_words) {
        this.overflowIndex = ci;
      }
      return totalWords;
    }, 0);
    return sum;
  }

  checkHtmlAmount = (arr) => {
    if(localStorage.getItem('ly_not_ask_again_MuchHtmlNotice')) {
      return false;
    }
    let htmlCount = 0;
    Object.entries(arr).map((item) => {
      if(item[0] === 'body_html' || item[0] === 'summary_html') {
        let plainTxt = item[1].replace(/<[^>]*>?/gm, '');
        let allWords = this.countWords({[item[0]]: item[1]});
        let txtWords = this.countWords({txt: plainTxt});
        let count = allWords - txtWords;
        if(count > htmlCount) htmlCount = count;
      }
    });
    return htmlCount > 250 ? true : false;
  }
 
  isAutoTranslationAvailable = () => {
    let emptyFields = false;
    if(this.state.data.find(x => (x.source !== '' && x.target === '' && !x.isHidden))) {
      emptyFields = true;
    }
    if(!emptyFields && this.state.data.find(x => x.type == 'subfields')) {
      this.state.data.filter(x => x.type == 'subfields').forEach(item => {
        if(item.id !== 'images') {
          if(!emptyFields && item.fields.find(x => (x.source !== '' && x.target === '' && !x.isHidden))) {
            emptyFields = true;
          }
        }
      });
    }
    return (this.state.data.length > 0 && emptyFields);
  }

  closeNotEnoughWordsDialog = () => {
    this.setState({showNotEnoughWordsDialog: false});
  }

  closeMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
  }
  cancelMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
    this.closeNotEnoughWordsDialog();
  }
  submitMuchHtmlNotice = () => {
    if(!this.state.showNotEnoughWordsDialog) {
      this.bulkTranslateSection(this.overflowIndex);
    }
  }

  onCloseRechargePlans = () => {
    this.setState({
      showRechargePlans: false
    })
  }

  onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        //console.log(res)
      }
    )
  }

  resizeList = () => {
    this.cache.clearAll();
    if (this.listRef) {
      this.listRef.forceUpdateGrid();
    }
  }

  calcRowHeight = (rows) => {
    if(rows === 5) return (window.innerWidth < 960) ? 213 : 143;
    return (window.innerWidth < 960) ? 213 : 119;
  }

  onRowsRendered = (event) => {

  }

  onFilterChange = (event) => {
    this.setState({ filterQuery: event.target.value })
  }

  onResetQuery = () => {
    this.setState({ 
      filterQuery: '' 
    });
  }



  clearScrollToIndex = () => {
    this.setState({ scrollToIndex: -1 });
  }

  rowRenderer = ({ index, isScrolling, key, parent, style }) => {

    if (isScrolling) {
      return (
        <div
          key={key}
          style={{...style}}>
          Scrolling...
        </div>
      );
    }

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => {
          let firstSubfieldsId = this.filteredData.findIndex(x => x.type === 'subfields');

          return(
            <div style={{...style}}>
              <TranslationField 
                {...this.filteredData[index]} 
                itemId={this.props.itemId}
                isPending={this.props.isPending} 
                from={this.props.from}
                to={this.props.to}
                rawData={this.filteredData[index].type == 'subfields' ? this.state.data : null}
                parentField={this.props.parentField ? this.props.parentField : null}
                forceUpdate={this.state.forceUpdate}
                forceOpen={this.props.forceOpen}
                isFirst={(index === firstSubfieldsId)}
                triggerClearAllTranslations={this.props.triggerClearAllTranslations}
                triggerDiscardChanges={this.props.triggerDiscardChanges}
                filterQuery={this.state.filterQuery}

                onAddItem={this.props.onAddItem}
                onDeleteItem={this.props.onDeleteItem}
                onChange={this.props.onChange} 
                onLoaded={measure}
                onExpandCollapsableFields={this.props.onExpandCollapsableFields}
                onFieldSelected={this.props.onFieldSelected}
                onSubfieldSelected={this.props.onSubfieldSelected}
                onSubfieldsExpand={this.props.onSubfieldsExpand}
                onSubfieldsExpanded={this.props.onSubfieldsExpanded}
                onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
              />
            </div>
          );
        }}
      </CellMeasurer>
    );
  }

  renderVirtualList = () => {
    const { t, data, scrollElement } = this.props;
    const { scrollToIndex } = this.state;

    return <div className="virtual-list">
      <WindowScroller
        ref={el => this._setWindowScrollerRef(el)}    
        scrollElement={document.querySelector(scrollElement ? scrollElement : '#mainFrame')}
        onResize={this.resizeList}
        onScroll={this.clearScrollToIndex}
      >  
        {({ height, registerChild, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight={true}>  
              {({ width }) => {
                return (
                  <div ref={el => registerChild(el)}>
                    <List 
                      {...this.props}
                      ref={el => this._setListRef(el)}
                      style={{ 
                        padding: !data.length ? 0 : '12px 24px', 
                        outline: 'none' 
                      }}
                      width={width}
                      height={height ? height : 0}
                      rowCount={this.filteredData.length}
                      deferredMeasurementCache={this.cache}
                      rowHeight={this.cache.rowHeight}
                      rowRenderer={this.rowRenderer.bind(this)}
                      onRowsRendered={this.onRowsRendered}
                      overscanRowCount={2}
                      autoHeight
                      scrollTop={scrollTop}
                      onScroll={onChildScroll}
                      scrollToAlignment="center"
                      scrollToIndex={scrollToIndex}
                    />
                  </div> 
                );
              }}
          </AutoSizer>
        )} 
      </WindowScroller>
    </div>
  }

  renderStaticList = () => {
    const { t, nested, itemId, gridItem, onChange, isPending, isReset, onExpandCollapsableFields } = this.props;
    const { data, filterQuery } = this.state;
    let firstSubfieldsId = this.filteredData.findIndex(x => x.type === 'subfields');

    return (<div 
        className={classNames('static-list', data.length == 0 && 'empty')} 
        //style={{ padding: (nested || data.length == 0) ? '0' : '0 24px' }}
      >
      {this.filteredData.map((n, i) => {
        //console.log(n)
          return (<TranslationField 
            {...n}
            key={n.id}
            itemId={itemId} 
            isPending={isPending}
            from={this.props.from}
            to={this.props.to}
            rawData={n.type == 'subfields' ? this.state.data : null}
            parentField={this.props.parentField}
            forceUpdate={this.state.forceUpdate}
            forceOpen={this.props.forceOpen}
            isFirst={(i === firstSubfieldsId)}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            filterQuery={this.state.filterQuery}

            onChange={onChange} 
            onDeleteItem={this.props.onDeleteItem ? this.props.onDeleteItem : n.onDeleteItem}
            onAddItem={this.props.onAddItem ? this.props.onAddItem : n.onAddItem}
            onExpandCollapsableFields={onExpandCollapsableFields}
            onFieldSelected={this.props.onFieldSelected}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />)}
        )
      } 
    </div>)  
  }

  renderAutoTranslationBar = () => {
    if(!this.props.shop.shop.auto_translations.enabled || REACT_APP_ENABLE_AUTO_TRANSLATIONS == 'false' || this.props.to === 'IA' || window.location.href.indexOf('/notifications') > -1 || window.location.href.indexOf('/sms_notifications') > -1) return null;
    
    const { t, classes } = this.props;
    const { loadingTranslations } = this.state;

    const usedAutoTranslationMargin = window.location.href.indexOf('/custom') > -1 ? 38 : 0;
    return <div className={classes.infoField}>
      <span>{t('itemsList.autoTranslateBarTitle')}</span> 
      {loadingTranslations ? 
        <CircularProgress size={24} className={classes.autoTranslationProgress} />
      :
        <Button 
          variant="outlined" 
          size="small" 
          disabled={loadingTranslations}
          onClick={() => this.bulkTranslateSection(null)} 
          style={{padding: '0 8px', marginRight: usedAutoTranslationMargin}}
        >
          <TranslateOutlined size={20} style={{marginRight: 8}} />
          {t('itemsList.autoTranslateBarButton', {wordsLeftNum: this.props.shop.shop.auto_translations.available_words})}
        </Button>
      }
    </div>
  }

  renderNoAutoTranslationsLeftBar = () => {
    const { t } = this.props;
    if(!this.props.shop.shop.auto_translations.enabled || REACT_APP_ENABLE_AUTO_TRANSLATIONS == 'false' || this.props.to === 'IA') return null;

    const { classes } = this.props;
    return <div className={classes.warningField}>
      <span>{t('itemsList.noAutoTranslateBarTitle')}</span> 
      <Button 
        variant="outlined" 
        size="small" 
        onClick={this.openRechargePlans} 
        style={{padding: '0 8px'}}
      >
          
          {t('itemsList.buyMoreWords')}
      </Button>
    </div>
  }

  renderFieldFilterBar = () => {
    const { t, classes } = this.props;
    const { filterQuery } = this.state;
    

    return <div style={{position: 'relative'}}>
      <InputBase
        style={{ padding: '8px 24px' }}
        onChange={this.onFilterChange}
        value={filterQuery}
        placeholder={t('itemsList.filterPlaceholder')}
        fullWidth
      />
      {filterQuery &&
        <div className={classes.closeIcon}>
          <CloseIcon onClick={this.onResetQuery}/>
        </div>
      }
      <Divider />
      {(filterQuery && this.filteredData.length == 0) &&
        <div style={{padding: 24}}>
          <Typography variant="h5" align="center">
            {t('itemsList.noMatchesFound')}
          </Typography>
        </div>
      }
    </div>
  }

  renderNotEnoughWordsDialog = () => {
    const { t } = this.props;
    return <Dialog
      onClose={this.closeNotEnoughWordsDialog}
      aria-labelledby="customized-dialog-title"
      open={this.state.showNotEnoughWordsDialog}
      //scroll="body"
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(255, 255, 255, .5)'
        }
       }
      }
    >
      <DialogTitle id="customized-dialog-title" onClose={this.closeImportFromOriginalDialog}>
        <Typography variant="h5" component="span">{t('itemsList.notEnoughWordsLeft')}</Typography>
      </DialogTitle>
      <DialogContent>
        {t('itemsList.notEnoughWordsLeftInfo')}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={this.closeNotEnoughWordsDialog} color="default" size="medium">
          {t('system.cancel')}
        </Button>
        {this.overflowIndex > -1 &&
          <Button variant="outlined" onClick={this.handleContinueTranslation} size="medium">
            {t('itemsList.translateAvailable')}
          </Button>
        }
        <Button variant="contained" color="secondary" size="medium" onClick={this.openRechargePlans}>
          <TranslateOutlined size={20} style={{marginRight: 8}} /> {t('autoTranslationsTile.getMoreWordsButton')}
        </Button>
      </DialogActions>
    </Dialog>
  }
  
  render() {
    const { 
      t,
      nested,
      isPending,
      classes,
      allowAutoTranslation,
      allowFieldFiltering,
      filterGroup,
      type,
      id,
      parentField
    } = this.props;

    const {
      data, 
      filterQuery,
      loadingTranslations,
      loadingTranslationsError,
      canAutoTranslate,
    } = this.state;

    // Use virtualized lists on the theme section and for subfields
    const virtualized = (
      !this._isShopifyContentPage() && 
      (type !== 'subfields' && id !== 'images') && 
      parentField !== 'json-editor'
    ); // data.length > 0; //

    this.filteredData = filterFields(data, filterGroup, filterQuery);

    return (
      <React.Fragment>
        {!nested &&
          <Divider />
        }
          
        {!this.props.isPending ?
          <React.Fragment>
            <RechargePlans
              visible={this.state.showRechargePlans}
              onClose={this.onCloseRechargePlans}
              onBuy={this.onBuyWords}
            />

            {(allowAutoTranslation && this.isAutoTranslationAvailable() && this.props.shop.shop.auto_translations.available_words > 0) &&
              this.renderAutoTranslationBar()
            }
            {(allowAutoTranslation && this.isAutoTranslationAvailable() && this.props.shop.shop.auto_translations.available_words <= 0) &&
              this.renderNoAutoTranslationsLeftBar()
            }
            {allowFieldFiltering &&
              null //this.renderFieldFilterBar()
            }
            {(data.length > 0 && virtualized) &&
              this.renderVirtualList()
            }
            {(data && !virtualized) &&
              this.renderStaticList()
            }
          </React.Fragment>
        :
          <ItemsListSkeleton 
            rows={5}
            hideTopToolbar={true}
            hideBottomToolbar={true}
            contained
          />
        }

        {(data.length === 0 && !isPending) &&
          <div>
            {
              this.props.emptyMessage ? (
                this.props.emptyMessage
              ) : (
                <div className={classes.noTranslationFieldsMsg}>{t('itemsList.noFieldsInSection')}</div>
              )
            }
          </div>
        }

        {this.renderNotEnoughWordsDialog()}

        <DontAskAgainDialog
          context="MuchHtmlNotice"
          open={this.state.showMuchHtmlNotice}
          onClose={this.closeMuchHtmlNotice}
          onCancel={this.cancelMuchHtmlNotice}
          onSubmit={this.submitMuchHtmlNotice}
          title={t('itemsList.htmlInContentTitle')}
          content={t('itemsList.htmlInContentDescription')}
          initialChecked
        />
        
        <Snackbar open={loadingTranslationsError} autoHideDuration={6000} onClose={() => this.setState({loadingTranslationsError: false})}>
          <MuiAlert onClose={() => this.setState({loadingTranslationsError: false})} severity="error" elevation={6} variant="filled">
            {t('system.whoopsMessage')}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>
    );
  }

  _setListRef = ref => {
    this.listRef = ref;
  };

  _setWindowScrollerRef = ref => {
    this.windowScrollerRef = ref;
  };

  _isShopifyContentPage = () => {
    for(var i=0; i < this.props.data.length; i++) {
      if(this.props.data[i].type === 'subfields') {
        return true;
        break;
      }
    }
    return false;
  }
}
TranslationFields.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  languages: languages,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAutoTranslations
    },
    dispatch
  )


const styles = theme => ({
  root: {
    //backgroundColor: '#ccc !important',
    //paddingLeft: 16,
    position: 'relative !important',
    margin: '0 -12px !important'
  },
  textField: {
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
  },
  inputField: {
    padding: '12px 14px !important',
    fontSize: '14px !important',
    boxSizing: 'border-box',
  },
  targetField: {
    borderStyle: 'dashed !important',
  },
  disabledField: {
    backgroundColor: '#fafafa',
  },
  gridItem: {
    margin: '0 -12px !important',
  },
  gridOdd: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0 !important',
      '&>div': {
        marginBottom: 0,
        '& fieldset': {
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginBottom: '0 !important',
        '& .redactor-box': {
          borderBottom: 'none',
          borderBottomLeftRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
        }
      },
    },
  },
  gridEven: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      '&>div': {
        marginTop: -1,
        '& fieldset': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginTop: -1,
        '& .redactor-box': {
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '0 !important',
        }
      },
    },
  },
  arrow: {
    position: 'absolute',
    cursor: 'pointer',
    left: '50%',
    top: '50%',
    margin: '-15px 0 0 -11px',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& svg': {
      //fontSize: 22,
    }
  },
  imageContainer: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
  },
  noTranslationFieldsMsg: {
    textAlign: 'center',
    padding: '50px 0'
  },
  infoField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f1f8e9',
    color: '#33691e',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#33691e',
      borderColor: '#33691e',
    }
  },
  warningField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#feefb3',
    color: '#9f6000',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#9f6000',
      borderColor: '#9f6000',
    }
  },
  autoTranslationProgress: {
    margin: 4,
    color: '#33691e',
    borderColor: '#33691e',
  },
  closeIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    right: 4,
    top: 10
  },
  label: {
    backgroundColor: '#fafafa',
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TranslationFields))));
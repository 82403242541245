import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import CollectionsIcon from '@material-ui/icons/CollectionsOutlined';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import BookIcon from '@material-ui/icons/BookOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import LinkIcon from '@material-ui/icons/LinkOutlined';
import PhotoIcon from '@material-ui/icons/PhotoOutlined';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import WebIcon from '@material-ui/icons/WebOutlined';
import SubtitlesIcon from '@material-ui/icons/SubtitlesOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import CreateIcon from '@material-ui/icons/CreateOutlined';
import SmsIcon from '@material-ui/icons/SmsOutlined';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import { withTranslation, useTranslation } from 'react-i18next';

class ListItemLink1 extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  loadScript(url, callback){
    const script = document.createElement("script")
    script.type = "text/javascript";

    if(script.readyState) {
      script.onreadystatechange = function(){
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function(){
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  renderLink = React.forwardRef((itemProps, ref) => <Link to={this.props.to} {...itemProps} />);

  render() {
    const { icon, primary, customStyle, disabled, selected } = this.props;
    return (
      <li>
        <ListItem button component={this.renderLink} disabled={disabled} selected={selected}>
          <ListItemIcon className={customStyle}>{icon}</ListItemIcon>
          <ListItemText style={{ padding: 0 }} primary={primary} />
        </ListItem>
      </li>
    );
  }
}

ListItemLink1.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

function ListItemLink2(props) {
  const { primary, to, target } = props;
  return (
    <li>
      <ListItem dense button component={target != 'blank' ? Link : 'a'} to={to} href={target == 'blank' ? to : null} target={target}>
        <ListItemText 
          primary={primary} 
          primaryTypographyProps={{
            style: {
              fontSize: 14
            }
          }}
        />
      </ListItem>
    </li>
  );
}

ListItemLink2.propTypes = {
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export const MainListItems = (props) => {
  const { t } = useTranslation();

  let uriParts = window.location.pathname.split('/');
  return <div>
    <ListItemLink1 button {...props} to="/" selected={!uriParts[1]} primary={t('navigation.primary.dashboard')} icon={<DashboardIcon />} />
    <ListItemLink1 button {...props} to="/theme" selected={uriParts[1] === 'theme'} primary={t('navigation.primary.theme')} icon={<LayersIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/products" selected={uriParts[1] === 'products'} primary={t('navigation.primary.products')} icon={<ShoppingCartIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/collections" selected={uriParts[1] === 'collections'} primary={t('navigation.primary.collections')} icon={<CollectionsIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/pages" selected={uriParts[1] === 'pages'} primary={t('navigation.primary.pages')} icon={<BookIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/navigation" selected={uriParts[1] === 'navigation'} primary={t('navigation.primary.navigation')} icon={<LinkIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/blogs" selected={uriParts[1] === 'blogs'} primary={t('navigation.primary.blogs')} icon={<SubtitlesIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/articles" selected={uriParts[1] === 'articles'} primary={t('navigation.primary.articles')} icon={<ReceiptIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/notifications" selected={uriParts[1] === 'notifications'} primary={t('navigation.primary.notifications')} icon={<MailIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/sms_notifications" selected={uriParts[1] === 'sms_notifications'} primary={t('navigation.primary.smsNotifications')} icon={<SmsIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/shop" selected={uriParts[1] === 'shop'} primary={t('navigation.primary.shop')} icon={<CodeIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/static_theme" selected={uriParts[1] === 'static_theme'} primary={t('navigation.primary.static')} icon={<TextFieldsIcon />} disabled={props.onboarding} />
    <ListItemLink1 button {...props} to="/custom" selected={uriParts[1] === 'custom'} primary={t('navigation.primary.custom')} icon={<CreateIcon />} disabled={props.onboarding} />
    {/*<ListItemLink1 button {...props} to="/metaobjects" selected={uriParts[1] === 'metaobjects'} primary={t('navigation.primary.metaobjects')} icon={<CreateIcon />} disabled={props.onboarding} />*/}
  </div>
};

export const SecondaryListItems = (props) => {
  const { t } = useTranslation();

  return <div>
    <li>
      <ListItem dense button onClick={(e) => {window.FreshworksWidget('open', 'ticketForm');}}>
        <ListItemText 
          primary={t('navigation.secondary.getSupport')} 
          primaryTypographyProps={{
            style: {
              fontSize: 14
            }
          }}
        />
      </ListItem>
    </li>
    <ListItemLink2 button to="http://support.langify-app.com/support/solutions/11000004136" target="blank" primary={t('navigation.secondary.documentation')} />
    <ListItemLink2 button to="/recommended_apps" primary={t('navigation.secondary.recommendedApps')} />
    <ListItemLink2 button to="/static/privacy" primary={t('navigation.secondary.privacyPolicy')} />
    <ListItemLink2 button to="/static/terms" primary={t('navigation.secondary.termsOfService')} />
  </div>
};

import React, { Component } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import API from '../../api/api.js'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { BulkTranslationSettings } from '../../components/BulkTranslations';



const useStyles = makeStyles({
  root: {
    maxWidth: 225,
  },
  selectMenu: {
    //fontSize: 14,
    //paddingBottom: 3,
    display: 'inline-block',
  }
})
const UILanguageSelector = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles(props);

  function changeLanguage(e) {
    //i18n.changeLanguage(e.target.value);
    window.localStorage.setItem('ly-ui-language', e.target.value);
    window.location.reload();
  }
  return(
    <FormControl variant="outlined" fullWidth={false} className={classes.root}>
      {/*<InputLabel id="ui-language-select-label">{t('system.uiLanguage')}</InputLabel>*/}
      <Select
        //label="UI language"
        labelId="ui-language-select-label"
        id="ui-language-select-outlined"
        onChange={changeLanguage} 
        value={i18n.language}
        classes={{selectMenu: classes.selectMenu }}
      >
        <MenuItem value='en'>{t('system.english')}</MenuItem>
        <MenuItem value='de'>{t('system.german')}</MenuItem>
        <MenuItem value='it'>{t('system.italian')}</MenuItem>
        <MenuItem value='fr'>{t('system.french')}</MenuItem>
        <MenuItem value='es'>{t('system.spanish')}</MenuItem>
      </Select>
    </FormControl>
  )
}

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      richtextEditorVersion: (props.langifyFeatures.find(x => x.name === 'editor_v2') && props.langifyFeatures.find(x => x.name === 'editor_v2').is_enabled) ? 'v2' : 'v1',
      richtextEditorOnParagraphFields: (props.langifyFeatures.find(x => x.name === 'html_text_2_rich_text') && props.langifyFeatures.find(x => x.name === 'html_text_2_rich_text').is_enabled) ? true : false,
      importV1ProductMetafieldsProgress: 0,
      progressingPoFile: false,
    };
  }

  onImportV1ProductMetafieldTranslations = () => {
  	const editLanguage = this.props.editLanguage;

    this.setState({
      importV1ProductMetafieldsProgress: 1,
    });

  	API.syncProducts(
  		editLanguage,
  		progress => {
  			console.log('sync products: ' + progress)
  		},
      response => {
        if(response.success == true) {
          this.syncProductMetafields(
            editLanguage,
            response.products.slice(0, 10),
            0,
            progress => {
              console.log('import translations: ' + progress);
              this.setState({
                importV1ProductMetafieldsProgress: progress,
              });
            },
            result => {
              console.log(result);
              this.setState({
                importV1ProductMetafieldsProgress: 100,
              });
            }
          )
        }
      }
    );
  };

  syncProductMetafields = (language, products, index, progress, result) => {
  	if(index >= products.length - 1) {
  		result('!');
  	} else {
	  	API.syncProductMetafields(
	  		products[index].id,
	  		language,
	  		progress => {},
	  		response => {
	  			if(response.success == true && response.metafields.length > 0) {
					API.importV1ProductMetafieldTranslations(
						language,
						products[index].id,
						response.metafields,
						progress => {},
						response => {
				  			progress(Math.ceil(index / (products.length - 1) * 100));
				  			this.syncProductMetafields(language, products, index + 1, progress, result);
						}
					);
	  			} else {
		  			progress(Math.ceil(index / (products.length - 1) * 100));
		  			this.syncProductMetafields(language, products, index + 1, progress, result);
	  			}
	  		}
  		)
  	}
  };

  onUploadPO = (e) => {
    this.setState({progressingPoFile: true});

    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    
    const formData = new FormData();
    formData.append('file', file);

    API.splitPOfile(
      formData,
      this.props.editLanguage,
      progress => {
        this.setState({progressingPoFile: true});
        console.log('Progress:', progress);
      },
      data => {
        this.setState({progressingPoFile: false});
        console.log('Result:', data);
      }
    );
  }

  toggleRichtextOnParagraphFields = (event) => {
    this.setState({
      richtextEditorOnParagraphFields: !this.state.richtextEditorOnParagraphFields
    }, () => {
      if(this.state.richtextEditorOnParagraphFields) {
        API.enableLangifyFeature('html_text_2_rich_text');
      } else {
        API.disableLangifyFeature('html_text_2_rich_text');
      }
    });
  }

  changeRichtextEditorVersion = (event) => {
    this.setState({
      richtextEditorVersion: event.target.value
    }, () => {
      if(this.state.richtextEditorVersion === 'v2') {
        API.enableLangifyFeature('editor_v2');
      } else {
        API.disableLangifyFeature('editor_v2');
      }
    });
  }



  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('tools.titleSettings')}
        </Typography>
        <Divider className="divider-white" />        
        <Paper className={classes.root} elevation="1">
          
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.editorVersionTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.editorVersionInfo')}
            </Typography>
            <RadioGroup aria-label="richtext editor" name="richTextEditorVersion" value={this.state.richtextEditorVersion} onChange={this.changeRichtextEditorVersion}>
              <FormControlLabel value="v2" control={<Radio />} label={`${t('tools.editorVersion')} 2.0`} />
              <FormControlLabel value="v1" control={<Radio />} label={`${t('tools.editorVersion')} 1.0`} />
            </RadioGroup>
          </FormControl>

          <div className={classes.spacer} />

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.editorParagraphTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.editorParagraphInfo')}
            </Typography>
            <FormControlLabel
              control={<Switch checked={this.state.richtextEditorOnParagraphFields} onChange={this.toggleRichtextOnParagraphFields} />}
              label={this.state.richtextEditorOnParagraphFields ? 'enabled' : 'disabled'}
            />
          </FormControl>

          <div className={classes.spacer} />

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.uiLanguageTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>{t('tools.uiLanguageInfo')}</Typography>
            <UILanguageSelector />
          </FormControl>
        </Paper>

                {/*
        <br />
        <Typography color="textPrimary" variant="h4" gutterBottom component="h2" classes={{root: classes.h2}}>
          Bulk translation
        </Typography>
        <Divider className="divider" />
        <Paper className={classes.root} elevation="1">
          <BulkTranslationSettings
            languages={this.props.languages}
          />
        </Paper>
    */}
                
        <br />
        <Typography color="textPrimary" variant="h4" gutterBottom component="h2" classes={{root: classes.h2}}>
          {t('tools.titleTools')}
        </Typography>
        <Divider className="divider" />
        <Paper className={classes.root} elevation="1">
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.poSplitTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.poSplitInfo')}
              <br />
              <br />
              {t('tools.poSplitUpload')}
              <br />
              <Button onClick={e => {this.import.click();}} variant="contained" color="secondary" disabled={this.state.progressingPoFile} fullWidth={false}>
                {t('tools.poSplitUploadSelect')} {this.state.progressingPoFile && <CircularProgress size={20} style={{marginLeft: 12}} />}
              </Button>
            </Typography>
            <input
              style={{display: 'none'}}
              accept={'.po'}
              ref={(ref) => this.import = ref}
              type="file"
              onChange={(event) => {this.onUploadPO(event)}}/>
          </FormControl>
        </Paper>

      </React.Fragment> 
    )
  }
}

const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  langifyFeatures: shop.shop.langify_features,
  editLanguage: shop.shop.edit_language,
  languages: languages.languages
})

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
    padding: 24,
  },
  subtext: {
    padding: 2,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  h2: {
    color: '#333 !important', 
    fontSize: 28
  },
  content: {
    color: '#999'
  },
  spacer: {
    height: 24
  }
})

export default withRouter(connect(
  mapStateToProps,
  null,
)(withStyles(styles)(withTranslation()(Settings))))
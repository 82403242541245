import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import shop from './shop.js'
import languages from './languages.js'
import themes from './themes.js'
import new_theme from './new_theme.js'
import static_theme from './static_theme.js'
import theme from './theme.js'
import pages from './pages.js'
import products from './products.js'
import collections from './collections.js'
import blogs from './blogs.js'
import articles from './articles.js'
import navigation from './navigation.js'
import customContents from './customContents.js'
import newCustomContents from './newCustomContents.js'
import images from './images.js'
import notifications from './notifications.js'
import SMSNotifications from './SMSNotifications.js'
import search from './search.js'
import stats from './stats.js'
import syncByShopifyId from './sync_by_shopify_id.js'
import switcherConfigurator from './switcherConfigurator.js'
import bulkTranslation from './bulkTranslation.js'
import langifyNotifications from './langifyNotifications.js'

export default (history) => combineReducers({
  router: connectRouter(history),
  shop,
  languages,
  themes,
  static_theme,
  new_theme,
  theme,
  customContents,
  newCustomContents,
  pages,
  products,
  collections,
  blogs,
  articles,
  navigation,
  images,
  notifications,
  SMSNotifications,
  search,
  stats,
  syncByShopifyId,
  switcherConfigurator,
  bulkTranslation,
  langifyNotifications
})

import { CircularProgress, MenuItem, MenuList, Paper, Popper, withStyles } from '@material-ui/core';
import { ChevronRightOutlined } from '@material-ui/icons';
import React from 'react'
import { withTranslation } from 'react-i18next';
import API from '../api/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAutoTranslations } from '../modules/shop';
import DontAskAgainDialog from './DontAskAgainDialog';
import OutOfWordsQuotaDialog from './OutOfWordsQuotaDialog';

class SourceToTargetMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingTranslations: false,
      anchorEl : null
    }

    this.importFromOriginal = this.importFromOriginal.bind(this);
  };
  
  showImportFromOriginalDialog = () => {
    this.setState({showImportFromOriginalDialog: true})
  }
  closeImportFromOriginalDialog = () => {
    this.setState({showImportFromOriginalDialog: false})
  }
  importFromOriginal = () => {
    if(this.props.data.target != this.props.data.source) {
      this.setState({usedText: this.props.data.source})

      this.props.onChange(null, {
        id: this.props.data.id,
        changed: true,
        initial: this.props.data.target,
        target: this.props.data.source,
        parentField: this.props.parentField,
      })
    }

    this.setState({showImportFromOriginalDialog: false});
  }

  updateAnchorEl = (anchorEl) => {
    this.setState({
      anchorEl: anchorEl
    })
  }

  closeAutoTranslateNotificationsWarning = () => {
    this.setState({showAutoTranslateNotificationsWarning: false})
  }

  autoTranslateField = (force = false) => {
    //debugger;
    if(!force && window.location.href.includes('notifications')) {
      this.setState({showAutoTranslateNotificationsWarning: true});

      return;
    }

    let root = this;
    const { from, to } = this.props;

    // Collect all section source strings in an array
    let stringsArr = {};
    stringsArr[this.props.data.id] = this.props.data.source.toString();

    // Handle events that block the process
    let wordsToTranslateNum = this.countWords(stringsArr, true);
    let hasMuchHtml = this.checkHtmlAmount(stringsArr);
    let outQuota = wordsToTranslateNum > this.props.shop.shop.auto_translations.available_words;
    if((hasMuchHtml && !force) || outQuota) {
      this.setState({
        showNotEnoughWordsDialog: false,
        showMuchHtmlNotice: false,
      }, () => {
        this.setState({
          loadingTranslations: false,
          showNotEnoughWordsDialog: outQuota,
          showMuchHtmlNotice: hasMuchHtml && !outQuota,
        });
      });
      return;
    }

    // Call API Servie and manage response
    this.setState({
      loadingTranslations: true,
    })

    API.autoTranslateStrings(from, to, stringsArr, (res) => {
      if(res.success === false || !res.auto_translations) {
        root.setState({ 
          loadingTranslations: false, 
          loadingTranslationsError: true, 
        });
        return null;
      }

      root.props.updateAutoTranslations(
        res.auto_translations.total_used_words,
        res.auto_translations.available_words,
      )

      // Update res of all section target fields
      root.setState({
        usedText: res.Strings[root.props.data.id],
        loadingTranslations: false, 
        loadingTranslationsError: false, 
      });

      root.props.onChange(null, {
        id: root.props.data.id,
        changed: true,
        initial: root.props.data.target,
        target: res.Strings[root.props.data.id],
        parentField: root.props.parentField,
      });
    });

  }

  countWords = (arr, calcOverflow) => {
    let deleteEmptySliceValues = function(inputArray) {
      var r = [];
      inputArray.forEach(function(item) {
        if (item !== "") {
          r.push(item);
        }
      });
      return r
    }

    let sum = Object.values(arr).reduce((pv, cv, ci) => {
      let singleSourceString = cv.toString();
      let charCount = singleSourceString.length;
      let calc_words_by_chars = charCount / 6;
      singleSourceString = singleSourceString.replace(/-/g, " ");
      singleSourceString = singleSourceString.replace(/</g, " ");
      singleSourceString = singleSourceString.replace(/>/g, " ");
      singleSourceString = singleSourceString.replace(/\"/g, " ");
      singleSourceString = singleSourceString.replace(/\n/g, " ");
      let wordSlice = singleSourceString.split(" ");
      wordSlice = deleteEmptySliceValues(wordSlice);

      let diff = calc_words_by_chars - wordSlice.length;
      if (diff < 0) diff = diff * -1;
      let diffPercent = (diff * 100) / wordSlice.length
      let price_words = wordSlice.length;
      if (diffPercent > 10 && calc_words_by_chars > wordSlice.length) {
          price_words = calc_words_by_chars;
      }
      let totalWords = pv + parseInt(price_words);

      return totalWords;
    }, 0);
    return sum;
  }

  checkHtmlAmount = (arr) => {
    if(localStorage.getItem('ly_not_ask_again_MuchHtmlNotice')) {
      return false;
    }
    let htmlCount = 0;
    Object.entries(arr).map((item) => {
      if(item[0] === 'body_html' || item[0] === 'summary_html') {
        let plainTxt = item[1].replace(/<[^>]*>?/gm, '');
        let allWords = this.countWords({[item[0]]: item[1]});
        let txtWords = this.countWords({txt: plainTxt});
        let count = allWords - txtWords;
        if(count > htmlCount) htmlCount = count;
      }
    });
    return htmlCount > 250 ? true : false;
  }

  closeMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
  }
  cancelMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
  }
  submitMuchHtmlNotice = () => {
    if(!this.state.showNotEnoughWordsDialog) {
      this.autoTranslateField(true);
    }
  }

  componentDidMount = () => {

    this.setState({
      source: this.props.data.source,
      target: this.props.data.target
    })

    if (this.props.onLoaded) {
      setTimeout(() => {
        if (this.hasMounted) {
          this.props.onLoaded()
        }
      }, 100)
    }
  }

  render() {

    const { t, classes, deletable, to } = this.props;
    const rightArrowMargin = deletable === true ? "9px": "11px";
    const leftArrowMargin = deletable === true ? "-30px": "inherit";
    const open = Boolean(this.state.anchorEl);

    return (
      <React.Fragment>
        <span
          onMouseOver={event => {
            this.updateAnchorEl(event.currentTarget)
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              if (!this.mouseIsOver) {
                this.updateAnchorEl(null)
              }
            }, 200)
          }}
          className={classes.arrow}
          style={{
            zIndex:"1",
            marginRight: rightArrowMargin,
            marginLeft: leftArrowMargin
          }}>
          <ChevronRightOutlined
            onClick={event => {
              this.updateAnchorEl(event.target)
            }}
          />
          {this.state.loadingTranslations && (
            <CircularProgress
              size={24}
              className={classes.autoTranslationProgress}
            />
          )}
        </span>
        <Popper
          id="render-props-popover"
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.updateAnchorEl(null)
          }}
          anchororigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformorigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          style={{ zIndex: '20000 !important' }}>
          <Paper>
            <MenuList
              dense={true}
              onMouseEnter={event => {
                this.mouseIsOver = true
              }}
              onMouseLeave={() => {
                this.mouseIsOver = false
                this.updateAnchorEl(null)
              }}>
              <MenuItem
                onClick={this.showImportFromOriginalDialog}
                align="center"
                dense={true}>
                <small>{t('itemsList.importFromOriginal')}</small>
              </MenuItem>
              {to !== 'IA' && this.props.parentField !== 'json-editor' && (
                <MenuItem
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    this.autoTranslateField(false)
                  }}
                  align="center"
                  dense={true}>
                  <small>{t('itemsList.autoTranslateSingleField')}</small>
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        </Popper>

        <DontAskAgainDialog
          context="ImportFromOriginal"
          open={this.state.showImportFromOriginalDialog}
          onClose={this.closeImportFromOriginalDialog}
          onSubmit={this.importFromOriginal}
          title={t('itemsList.importFromOriginal')}
          content={t('itemsList.importFromOriginalDescription')}
        />
        <DontAskAgainDialog
          context="showAutoTranslateNotificationsWarning"
          open={this.state.showAutoTranslateNotificationsWarning}
          onClose={this.closeAutoTranslateNotificationsWarning}
          onSubmit={() => {this.autoTranslateField(true)}}
          title={t('itemsList.notificationNoticeTitle')}
          content={t('itemsList.notificationNoticeDescription')}
        />
        <DontAskAgainDialog
          context="MuchHtmlNotice"
          open={this.state.showMuchHtmlNotice}
          onClose={this.closeMuchHtmlNotice}
          onCancel={this.cancelMuchHtmlNotice}
          onSubmit={this.submitMuchHtmlNotice}
          title={t('itemsList.htmlInContentTitle')}
          content={t('itemsList.htmlInContentDescription')}
          initialChecked
        />
        <OutOfWordsQuotaDialog
          open={this.state.showNotEnoughWordsDialog}
        />

      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  languages: languages,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAutoTranslations
    },
    dispatch
  )

const styles = theme => ({
  arrow: {
    position: 'absolute',
    cursor: 'pointer',
    left: '50%',
    top: '50%',
    margin: '-15px 0 0 -11px',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '& svg': {
      //fontSize: 22,
    }
  },
  autoTranslationProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
) (withStyles(styles) (withTranslation()(SourceToTargetMenu)))

import API from '../api/api'
import Cookies from 'universal-cookie';

const GET_THEMES_PENDING = 'GET_THEMES_PENDING'
const GET_THEMES_RESPONSE = 'GET_THEMES_RESPONSE'
const PUBLISH_TRANSLATIONS_PENDING = 'PUBLISH_TRANSLATIONS_PENDING'
const PUBLISH_TRANSLATIONS_PROGRESS = 'PUBLISH_TRANSLATIONS_PROGESS'
const PUBLISH_TRANSLATIONS_RESPONSE = 'PUBLISH_TRANSLATIONS_RESPONSE'
const UNPUBLISH_TRANSLATIONS_PENDING = 'UNPUBLISH_TRANSLATIONS_PENDING'
const UNPUBLISH_TRANSLATIONS_PROGRESS = 'UNPUBLISH_TRANSLATIONS_PROGESS'
const UNPUBLISH_TRANSLATIONS_RESPONSE = 'UNPUBLISH_TRANSLATIONS_RESPONSE'
const REFRESH_TRANSLATIONS_PENDING = 'REFRESH_TRANSLATIONS_PENDING'
const REFRESH_TRANSLATIONS_PROGRESS = 'REFRESH_TRANSLATIONS_PROGESS'
const REFRESH_TRANSLATIONS_RESPONSE = 'REFRESH_TRANSLATIONS_RESPONSE'

const EDIT_THEME_CHANGED = 'EDIT_THEME_CHANGED'
const CHANGE_EDIT_THEME_PENDING = 'CHANGE_EDIT_THEME_PENDING'

const cookies = new Cookies();

const initialState = {
  master_loading: false,
  progress: 0,
  get_themes_pending: true,
  is_published: false, 
  publish_transations_pending: false,
  unpublish_transations_pending: false,
  refresh_transations_pending: false,
  error: false,
  themes: [],
  edit_theme: null,
  change_edit_theme_pending: false
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case GET_THEMES_PENDING: {
      return {
        ...state,
        get_themes_pending: true,
        master_loading: true,
      }
    }
    case GET_THEMES_RESPONSE: {
      if(action.response.success === true) {
        let theme = cookies.get('theme')
        if(!theme) {
            const usedTheme = action.response.themes.find(x => x.role === 'main')
            if(usedTheme) {
              theme = usedTheme.id
            }
        }
        return {
          ...state,
          get_themes_pending: false,
          master_loading: false,
          error: false,
          themes: action.response.themes,
          edit_theme: theme
        }
      } else {
        return {
          ...state,
          get_themes_pending: false,
          master_loading: false,
          error: true
        }
      }
    }
    
    case CHANGE_EDIT_THEME_PENDING: {
      return {
        ...state,
        change_edit_theme_pending: true
      }
    }
    case EDIT_THEME_CHANGED: {
      return {
        ...state,
        change_edit_theme_pending: false,
        edit_theme: action.theme
      }
    }

    case PUBLISH_TRANSLATIONS_PENDING: {
      return {
        ...state,
        master_loading: true,
        publish_transations_pending: true
      }
    }
    case PUBLISH_TRANSLATIONS_PROGRESS: {
      return {
        ...state
      }
    }
    case PUBLISH_TRANSLATIONS_RESPONSE: {
      if(action.response.success === true) {
        let newThemes = state.themes;
        let index = state.themes.findIndex(item => item.id == state.edit_theme);
        newThemes[index].prepared = true;
        return {
          ...state,
          themes: newThemes,
          publish_transations_pending: false,
          is_published: true, 
        }        
      } else {
        return {
          ...state,
          publish_transations_pending: false,
          is_published: false, 
        }          
      }
    }

    case UNPUBLISH_TRANSLATIONS_PENDING: {
      return {
        ...state,
        unpublish_transations_pending: true
      }
    }
    case UNPUBLISH_TRANSLATIONS_PROGRESS: {
      return {
        ...state
      }
    }
    case UNPUBLISH_TRANSLATIONS_RESPONSE: {
      if(action.response.success === true) {
        let newThemes = state.themes;
        let index = state.themes.findIndex(item => item.id == state.edit_theme);
        newThemes[index].prepared = false;
        return {
          ...state,
          theme: newThemes,
          unpublish_transations_pending: false,
          is_published: false, 
        }        
      } else {
        return {
          ...state,
          unpublish_transations_pending: false,
          is_published: true, 
        }          
      }
    }

    case REFRESH_TRANSLATIONS_PENDING: {
      return {
        ...state,
        refresh_transations_pending: true,
        is_published: true, 
      }
    }
    case REFRESH_TRANSLATIONS_PROGRESS: {
      return {
        ...state
      }
    }
    case REFRESH_TRANSLATIONS_RESPONSE: {
      return {
        ...state,
        refresh_transations_pending: false
      }
    }

    default:
      return state
  }
}




// ACTIONS CREATORS
export const getThemes = () => {  
  return dispatch => {
    dispatch({
      type: GET_THEMES_PENDING
    })

    API.getThemes(response => {
      dispatch({
        type: GET_THEMES_RESPONSE,
        response: response
      })
    })
  }
}

export const publishTranslations = (themeId) => {  
  return dispatch => {
    dispatch({
      type: PUBLISH_TRANSLATIONS_PENDING,
    });
    API.addV3Scripts(
      themeId, 
      response => {
        dispatch({
          type: PUBLISH_TRANSLATIONS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const unpublishTranslations = (themeId) => {  
  return dispatch => {
    dispatch({
      type: UNPUBLISH_TRANSLATIONS_PENDING,
    });
    API.removeV3Scripts(
      themeId, 
      response => {
        dispatch({
          type: UNPUBLISH_TRANSLATIONS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const refreshTranslations = (themeId) => {  
  return dispatch => {
    dispatch({
      type: REFRESH_TRANSLATIONS_PENDING,
    });
    API.removeV3Scripts(
      themeId, 
      response => {
        API.addV3Scripts(
          themeId, 
          response => {
            dispatch({
              type: REFRESH_TRANSLATIONS_RESPONSE,
              response: response
            });
          }
        )
      }
    )
  }
}

export const toggleStrictmode = (themeId) => {  
  return dispatch => {
    dispatch({
      type: GET_THEMES_PENDING
    })

    API.getThemes(response => {
      dispatch({
        type: GET_THEMES_RESPONSE,
        response: response
      })
    })
  }
}

export const setEditTheme = (theme) => {
  return dispatch => {
    dispatch({
      type: CHANGE_EDIT_THEME_PENDING
    });

    cookies.set('theme', theme, {path: '/'})

    dispatch({
      type: EDIT_THEME_CHANGED,
      theme: theme
    });

    /*
    API.addV3Scripts(
      theme,
      response => {
        cookies.set('theme', theme, {path: '/'})

        dispatch({
          type: EDIT_THEME_CHANGED,
          theme: theme
        })
      })
      */
  }
}
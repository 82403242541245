import React from 'react'
import withRouter from 'with-router'
import { getShop, setEditLanguage, logout } from '../../modules/shop'
import { getLanguages } from '../../modules/languages'
import { getThemes, setEditTheme } from '../../modules/themes';
import { updateSwitcherLanguages } from '../../modules/switcherConfigurator';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
import { ParallaxProvider, withController, Parallax } from 'react-scroll-parallax';
import smoothscroll from 'smoothscroll-polyfill';

import { MainListItems, SecondaryListItems } from './Navigation';
import Login from '../auth'
import Dashboard from '../dashboard'
import Theme from '../theme'
import NewTheme from '../new_theme'
import StaticTheme from '../static_theme'
import Products from '../products'
import Collections from '../collections'
import Pages from '../pages'
import Navigation from '../navigation'
import Blogs from '../blogs'
import Articles from '../articles'
import Notifications from '../notifications'
import NewNotifications from '../new_notifications'
import SMSNotifications from '../sms_notifications'
import Images from '../images'
import Shop from '../shop'
import Custom from '../custom'
import NewCustom from '../new_custom'
import About from '../about'
import Static from '../static'
import Design from '../design'
import Changelog from '../changelog'
import Settings from '../settings'
import Admin from '../admin'
import RecommendedApps from '../recommended_apps'
import MetaObjects from '../metaobjects'

import { ReactComponent as Logo } from '../design/logo.svg';
import Search from '../../components/Search';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBoundary from '../../components/ErrorBoundary';
import RechargeConfirmation from '../../components/RechargeConfirmation';
import { BulkTranslationStatusCard } from '../../components/BulkTranslations';

import '../../i18n';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import API from '../../api/api';

import $ from 'jquery';

const drawerWidth = 240;

class App extends React.Component {

  static propTypes = {
    parallaxController: PropTypes.object,
  };

  constructor(props) {
    super(props);

    window.mainFrameRef = React.createRef();

    window.dev = {
      clearHost: () => {
        if(API.clearHost()) {
          window.location.reload();
        }
      },
      changeHost: (host) => {
        if(API.changeHost(host)) {
          window.location.reload();
        }
      },
      enableLogging: () => {
        API.enableLogging(response => {
          response.success ? console.log('%clogging enabled', 'color:#006600') : console.log('%cerror', 'color:#660000');
        })
        return 'pending...';
      },
      disableLogging: () => {
        API.disableLogging(response => {
          response.success ? console.log('%clogging disabled', 'color:#006600') : console.log('%cerror', 'color:#660000');
        })
        return 'pending...';
      },
    }

    this.state = {
      open: localStorage.getItem('sidebarOpened') ? (localStorage.getItem('sidebarOpened') === 'true') : true,
      theme: '',
      labelWidth: 0,
      showNewChargeDialog: this.props.shop.shop.update_charge ? true : false,
    };
  }

  getScripts = (scripts, callback) => {
    var progress = 0;
    scripts.forEach(function(script) { 
      $.getScript(script, function () {
        if (++progress == scripts.length) callback();
      }); 
    });
  }

  componentDidMount = () => {
    const redactorSources = [
      '/redactorx/redactorx-langify.js',
      '/redactor/redactor.js'
    ];
    const redactorScripts =  [
      '/redactorx/plugins/alignment/alignment.js',
      '/redactorx/plugins/underline/underline.js',
      '/redactorx/plugins/imageposition/imageposition.js',
      '/redactorx/plugins/imageresize/imageresize.js',
      '/redactorx/plugins/inlineformat/inlineformat.js',
      '/redactorx/plugins/removeformat/removeformat.js',
      '/redactorx/plugins/readonly/readonly.js',
      '/redactorx/plugins/fontcolor/fontcolor.js',
      `/redactorx/locale/${this.props.i18n.language}.js`,
      
      '/redactor/plugins/fontcolor/fontcolor.js',
      '/redactor/plugins/fontsize/fontsize.js',
      '/redactor/plugins/table/table.js',
      '/redactor/plugins/imagemanager/imagemanager.js',
      '/redactor/plugins/video/video.js',
      '/redactor/plugins/fontfamily/fontfamily.js',
      '/redactor/plugins/alignment/alignment.js',
      '/redactor/plugins/pastemanager/pastemanager.js',
      '/redactor/plugins/noeditor/noeditor.js'
    ];

    this.getScripts(redactorSources, () => {
      this.getScripts(redactorScripts, () => {
        API.startSession(data => {
          if(data.success == true) {
            this.props.getShop();
            this.props.getLanguages();
            this.props.getThemes();


            //this.setState({showUpdateInformation: true});
          }
        });

        // kick off the polyfill!
        smoothscroll.polyfill();

        this.lastLocation = this.props.location.pathname;
        this.props.history.listen((location, action) => {
          if(this.lastLocation.split('/')[1] !== location.pathname.split('/')[1]) {
            this.lastLocation = location.pathname;
            if(window.mainFrameRef) {
              window.mainFrameRef.scroll({top: 0, behavior: 'smooth' });        
            }
          }
        });
      });
    });
  };

  componentWillReceiveProps = (newProps) => {
    if(this.props.shop.shop.update_charge !== newProps.shop.shop.update_charge) {
      this.setState({'showNewChargeDialog': newProps.shop.shop.update_charge})
    }
  }

  componentDidUpdate = () => {
    if(this.props.shop.get_shop_pending === false && this.props.languages.get_languages_pending === false && this.props.themes.get_themes_pending === false) {

      window.FreshworksWidget('prefill', 'ticketForm', {
        subject: `Support request for langify v2`,
        custom_fields: {
          cf_shop_url: this.props.shop.shop.url,
          cf_shopify_plan: this.props.shop.shop.plan,
          cf_langify_version: 'v2',
        }
      });      
      window.FreshworksWidget('show');
      window.FreshworksWidget('show', 'launcher');
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_shop_url', 'custom_fields.cf_shopify_plan', 'custom_fields.cf_langify_version']);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.hasError = true;
  }

  componentWillUnmount() {
    if(!this.hasError && window.mainFrameRef) {
      this.props.parallaxController.destroy();
    }
  }
  
  setMainFrameRef = (ref) => {
    window.mainFrameRef = ref;

    if(!this.hasError && window.mainFrameRef) {
      this.props.parallaxController.updateScrollContainer(window.mainFrameRef);
      this.props.parallaxController.update();
    }
  };

  handleDrawerOpen = () => {
    localStorage.setItem('sidebarOpened', 'true');
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    localStorage.setItem('sidebarOpened', 'false');
    this.setState({ open: false });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    this.props.logout()
  }

  handleSelectTheme = (event) => {
    //API.addV3Scripts(event.target.value, (res) => {});
    this.props.setEditTheme(event.target.value);
    //this.props.updateSwitcherLanguages(this.props.languages.languages, event.target.value, this.props.themes.themes);
  }

  updateCharge = () => {
    API.updateCharge(response => {console.log("!")})
  }

  render() {
    const { t, classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let rechargeConfirmation = null
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('recharge_success')) {
      let usedPlan = urlParams.get('recharge_success')
      let usedWords = 0
      if(usedPlan == 'basic' || usedPlan == 'business' || usedPlan == 'professional') {
        if(usedPlan == 'basic') {
          usedWords = '10 000'
        } else if(usedPlan == 'business') {
          usedWords = '20 000'
        } else if(usedPlan == 'professional') {
          usedWords = '50 000'
        }
        rechargeConfirmation = {
          plan: usedPlan,
          words: usedWords
        }
      }
    }

    
    if(this.props.shop.get_shop_pending === true || this.props.languages.get_languages_pending === true || this.props.themes.get_themes_pending === true) {
      return (
        <div style={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10000000000000,
          width: '100%',
          width: '100vw',
          height: '100vh',
          background: '#fff',
        }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ 
              minHeight: '100vh',
              height: '100vh',
              background: 'radial-gradient(circle, rgba(200,200,200,0) 0%, rgba(200,200,200,0) 70%, rgba(200,200,200,1) 100%)',
            }}
          >
            <Grid item xs={3} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
              <Logo />
              <LoadingIndicator
                withLogo={false}
                pending={true}
                loadingType={'initializing'}
                /*
                styles={{
                  root: classes.loadingIndicatorRoot,
                  circularProgress: classes.circularProgress,
                }}
                */
              />
            </Grid>   
          </Grid> 
        </div>
      )
    }
    if(this.props.shop.error === true) {
      return <div>error</div>
    }

    const currentLanguage = this.props.shop.shop.edit_language
    const selectableLanguages = this.props.languages.languages.filter(x => x.base === false)
    if(currentLanguage == 'Language') {
      if(selectableLanguages.length > 0) {
        this.props.setEditLanguage(selectableLanguages[0].id)
      }
    } else {
      if(selectableLanguages.length > 0) {
        if(!selectableLanguages.find(x => x.id == currentLanguage)) {
          this.props.setEditLanguage(selectableLanguages[0].id)
        }
      } else {
        this.props.setEditLanguage('Language')
      }
    }

    return (
      <React.Fragment>
        {
          rechargeConfirmation &&
          <RechargeConfirmation
            plan={rechargeConfirmation.plan}
            words={rechargeConfirmation.words}
          />
        }

        <CssBaseline />
        <div className={classNames(classes.root, this.state.open && 'open-sidebar')}>

          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
            elevation={0}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>

              <IconButton color="inherit" aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hidden,
                )}>
                <MenuIcon />
              </IconButton>

              <a href="/" className={classNames(classes.appBarLogo, this.state.open && classes.hidden)}>
                <img className="logo-img" src="/langify-logo-light.png" style={{width: 110}} alt={"logo"}/>
              </a>

              {/*
              <div className={classNames('search-form', classes.hideSmDown, classes.search, !this.state.open && classes.searchOpen)}>
                <Search theme={this.props.themes.edit_theme} />
              </div>
              */}

              <FormControl variant="outlined" className={classNames(classes.hideXsDown, classes.formControl, !this.state.open && classes.searchOpen)}>
                <Select 
                  placeholder="Language"
                  value={this.props.shop.shop.edit_language ? this.props.shop.shop.edit_language : 'Language'}
                  onChange={e => {this.props.setEditLanguage(e.target.value)}}
                  renderValue={(val) => <span style={{fontWeight: 400}}>
                      {this.props.languages.languages.find(x => x.base == true).name} 
                      <ArrowForwardIosOutlined style={{fontSize: 10, margin: '0 4px'}} />
                      {(this.props.languages.languages.length > 1 && this.props.languages.languages.find(x => x.id == this.props.shop.shop.edit_language)) ? `${this.props.languages.languages.find(x => x.id == this.props.shop.shop.edit_language).name}` : 'Language'}
                    </span>
                  }
                  classes={{
                    icon: classes.selectIcon,
                  }}
                  MenuProps={{
                    classes: {
                      paper: classes.selectMenu
                    }
                  }}
                  input={
                    <OutlinedInput
                      className={`current-language-select`}
                      labelWidth={this.state.labelWidth}
                      classes={{
                        input: classes.selectInput,
                        notchedOutline: classes.selectOutline,
                        //icon: classes.selectIcon,
                      }}
                      placeholder="Language"
                      margin="dense"
                      name="language"
                      id="outlined-language"
                    />
                  }
                >
                  <MenuItem disabled={true} value={'Language'}><em>From {this.props.languages.languages.find(x => x.base == true).name} to </em></MenuItem>
                  {
                    this.props.languages.languages.map((item, i) => {
                      if(!item.base && !item.removed_on_shopify) {
                        return <MenuItem key={`language-item-${i}`} value={item.id}>{item.name}</MenuItem>
                      }
                    })
                  }
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" className={classNames(classes.hideXsDown, classes.formControl)} style={{marginLeft: 16}}>
                <Select 
                  className={`current-theme-select`}
                  placeholder="Theme"
                  value={this.props.themes.edit_theme}
                  onChange={this.handleSelectTheme}
                  renderValue={(val) => <span style={{fontWeight: 400}}>
                    Theme - {this.props.themes.themes.find(x => x.id == val) ? this.props.themes.themes.find(x => x.id == val).name : 'Select'}</span>}
                  classes={{
                    icon: classes.selectIcon,
                  }}
                  MenuProps={{
                    classes: {
                      paper: classes.selectMenu
                    }
                  }}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      classes={{
                        input: classes.selectInput,
                        notchedOutline: classes.selectOutline,
                        //icon: classes.selectIcon,
                      }}
                      placeholder="Theme"
                      margin="dense"
                      name="theme"
                      id="outlined-theme"
                    />
                  }>
                  <MenuItem disabled={true} value={'Theme'}><em>Theme</em></MenuItem>
                  {
                    this.props.themes.themes.map((item, i) => {
                      return <MenuItem key={`language-item-${i}`} value={item.id} style={{fontWeight: item.role === 'main' ? '500' : 'initial'}}>{item.role === 'main' ? `${item.name} <- main theme` : item.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>


              <div className={classes.grow} />
              

              {/*
              <IconButton className="start-tutorial-btn" color="inherit">
                <InfoOutlinedIcon />
              </IconButton>
              */}

              <Button className={classNames(classes.hideSmDown, classes.backToStoreBtn)} href={`https://${this.props.shop.shop.url}`} target="_blank" color="inherit">
                {this.props.shop.shop.url.replace('.myshopify.com', '')}
              </Button>
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                  className={classNames(classes.profileDropdownTrigger)}
                >
                  <Avatar className={classNames('xxx', classes.avatar)}>
                    { this.props.shop.shop.url.substring(0, 2).toUpperCase() }
                  </Avatar>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                  classes={{paper: classes.profileMenu}}
                >
                  <MenuItem ><a href={`https://${this.props.shop.shop.url}/admin`} target="_blank" style={{textDecoration: 'none', color: '#000'}}><ArrowBackOutlined /> {t('navigation.account.backToShopify')}</a></MenuItem>
                  <MenuItem linkButton component={Link} to="/settings" onClick={this.handleClose}>{t('navigation.secondary.settingsAndTools')}</MenuItem>
                  <MenuItem onClick={this.logout}>{t('navigation.account.logout')}</MenuItem>
                </Menu>               
              </div>

            </Toolbar>
          </AppBar>
          
          
          

          <Drawer
            className="sidebar-drawer"
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.sidebarHeader}>
              <div className={classes.toolbarIcon}>
                <a href="/" className={classNames('logo-img', classes.sidebarLogo)}>
                  <img src="/langify-logo-dark.png" style={{width: 110}} alt={"langify logo"}/>
                </a>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
            <div className={classes.sidebarWrapper}>
              <div className={classNames('search-form', classes.search)}>
                <ErrorBoundary>
                  <Search theme={this.props.themes.edit_theme} />
                </ErrorBoundary>
              </div>
              <Divider />
              <List className="side-menu">
                <MainListItems 
                  onboarding={this.props.languages.languages.length > 1 && this.props.shop.shop.edit_language !== 'Language' ? false : true}
                  shop={this.props.shop}
                  customStyle={classes.sidebarItemIcon} 
                />
              </List>
              <Divider />
              <List className={classNames('side-submenu', !this.state.open && classes.hidden)}>
                <SecondaryListItems shop={this.props.shop}/>
              </List>
            </div>
          </Drawer>
          <main 
            id="mainFrame" 
            ref={this.setMainFrameRef} 
            className={classNames(classes.content)}
          >
            <div className={classes.appBarSpacer} />
            
            <Parallax y={[-50, 0]}>
              <div className={classNames(classes.mainContentBg, 'main-content-bg')} />
            </Parallax>

            <div className={classNames(classes.pageContent, 'page-content')}>
              <Route exact path="/" component={Dashboard} />
              <Route path="/theme" component={NewTheme} />
              <Route path="/static_theme" component={StaticTheme} />
              <Route path="/products" component={Products} />
              <Route path="/collections" component={Collections} />
              <Route path="/pages" component={Pages} />
              <Route path="/navigation" component={Navigation} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/articles" component={Articles} />
              <Route path="/images" component={Images} />
              <Route path="/shop" component={Shop} />
              <Route path="/custom" component={NewCustom} />
              <Route path="/sms_notifications" component={SMSNotifications} />
              <Route path="/notifications" component={NewNotifications} />
              <Route exact path="/static" component={Static} />              
              <Route exact path="/static/:id" component={Static} />  
              <Route exact path="/about" component={About} />
              <Route exact path="/login" component={Login} />              
              <Route exact path="/design" component={Design} />          
              <Route exact path="/changelog" component={Changelog} />  
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/hidden_admin" component={Admin} />
              <Route exact path="/recommended_apps" component={RecommendedApps} />
              <Route exact path="/metaobjects" component={MetaObjects} />
            </div>
            
            <div className={'footer'}>
              <div className={classNames('main-footer-bg')}>
                <Typography component="p" variant="caption" style={{textAlign: "center", color: '#ccc', marginBottom: 8, width: '100%'}}>
                  <img className="logo-img" src="/langify-logo-dark.png" style={{width: 150, opacity: .15, marginBottom: 20, marginTop: 40}} alt={"logo"}/>
                  <br />
                  Copyright &copy; {new Date().getFullYear()} Langify GmbH & Ko. KG
                </Typography>
              </div>             
            </div>

            <LoadingIndicator pending={this.props.themes.change_edit_theme_pending} withLogo/>
          </main>
        </div>

        <BulkTranslationStatusCard />

        <Dialog
          open={this.state.showNewChargeDialog}
          onClose={() => { this.setState({showNewChargeDialog: false}); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">Updated charge available</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Your charge has been updated. The  new charge will be ${this.props.shop.shop.updated_charge && this.props.shop.shop.updated_charge.toFixed(2)}/month.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.updateCharge} download color="primary">Continue</Button>
          </DialogActions>
        </Dialog>

        {/**
         * Shopify Markets Update Information
         * TODO: Remove again in the future
         */}
        {/*this.state.showUpdateInformation && !window.localStorage.getItem('ly_dismissed_markets_update_information') &&
          <Dialog
            open={true}
            disableBackdropClick={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropProps={{
              style: {
                backgroundColor: 'rgba(255, 255, 255, .5)'
              }
            }}
          >
            <DialogTitle id="alert-dialog-title">Update information</DialogTitle>
            <DialogContent>
            We recently released an update to fully support new Shopify features.
The update includes a few breaking changes affecting the language switcher and the language detection. Please contact our support if language selecting should not behave as usual.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {this.setState({showUpdateInformation: false}); window.localStorage.setItem('ly_dismissed_markets_update_information', true)}} variant="contained" color="secondary">Got it</Button>
            </DialogActions>
          </Dialog>
          */}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  profileMenu: {
    top: '4px !important',
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: 'rgba(26, 35, 126, .9)',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    zIndex: 100,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarLogo: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: theme.spacing(7),
      right: theme.spacing(7),
      textAlign: 'center'
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 4,
    }
  },
  hidden: {
    display: 'none',
  },
  hideSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  hideXsDown: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  title: {
    flexGrow: 1,
  },
  sidebarWrapper: {
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'absolute',
    top: 64,
    right: 0,
    bottom: 0,
    left: 0,
    [theme.breakpoints.down('xs')]: {
      top: 56,
    }
  },
  sidebarHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    zIndex: 1010,
    '& hr': {
      marginTop: -1,
    }
  },
  sidebarItemIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      //marginRight: 0,
    }
  },
  sidebarLogo: {
    position: 'absolute',
    left: 0,
    width: 240,
    textAlign: 'center',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto !important',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },

  button: {
    margin: theme.spacing(1),
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.02),
    },
    marginRight: 0,
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    paddingTop: 2,
    paddingBottom: 2,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    }
  },
  searchOpen: {
    marginLeft: theme.spacing(6),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  targetLangSelector: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 32,
    paddingLeft: 8,    
  },

  selectOutline: {
    borderColor: 'rgba(255, 255, 255, 0.23) !important',
  },
  selectIcon: {
    color: 'rgba(255, 255, 255, 0.54) !important',
  },
  selectInput: {
    paddingTop: 10, 
    paddingBottom: 10, 
    color: '#fff',
    maxWidth: 500,
    width: 'auto',
    fontWeight: 300,
  },
  loadingProgress: {
    color: 'black',
  },
  selectMenu: { 
    top: '14px !important',
  },

  backToStoreBtn: {
    textTransform: 'initial',
  },
  profileDropdownTrigger: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  mainContentBg: {
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(7)
    }
  },

  pageContent: {
    minHeight: 350
  },

  loadingIndicatorRoot: {
    backgroundColor: 'none !important',
    position: 'relative !important',
    left: '0px !important',
    padding: '24px !important',
  },
  circularProgress: {
    color: '#red !important', 
    margin: '24px !important',
  }, 
});

const mapStateToProps = ({ shop, languages, themes, bulkTranslation }) => ({
  shop: shop,
  languages: languages,
  themes: themes,
  shopUrl: shop.shop.url,
  bulkTranslation: bulkTranslation,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getShop,
      getLanguages,
      getThemes,
      setEditLanguage,
      setEditTheme,
      logout,
      updateSwitcherLanguages,
    },
    dispatch
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withController(withStyles(styles)(withTranslation()(App)))))